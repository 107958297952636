import * as React from "react";
import utility from "../../classes/utility";
import { Config } from "../../classes/config";
import ScrollView from "devextreme-react/scroll-view";
import Grid from "@material-ui/core/Grid";
import AlertDialog from "../common/alertDialog";
import CheckBoxInput from "../common/reusableComponents/CheckBox";
import TextBoxInput from "../common/reusableComponents/TextBox";
import Spinner from "../common/reusableComponents/Spinner";
import SelectBoxInput from "../common/reusableComponents/SelectBox";
import DateBoxInput from "../common/reusableComponents/DateBox";
import TextAreaInput from "../common/reusableComponents/TextArea";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DataSource from "devextreme/data/data_source";
import { SelectBox } from "devextreme-react/select-box";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import DeleteIcon from "@material-ui/icons/Delete";
import MapIcon from "@material-ui/icons/Map";
import Paper from "@material-ui/core/Paper";
import AssetSearch from "../AssetSearch/AssetSearch";
import AssetMap from "../AssetSearch/AssetMap";
import global from "../../classes/global";
import { Autocomplete } from "devextreme-react/autocomplete";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
let failure: any
let cause: any

export default class EditWorkOrder extends React.Component<any, any> {
  public workorder = this.props.workorder;
  public currentUser: any;

  constructor(props: any) {
    super(props);
    this.update = this.update.bind(this);
    this.SearchClicked = this.SearchClicked.bind(this);
    this._getContractors = this._getContractors.bind(this);
    this.currentUser = this.props.currentUser;
    this.state = {
      showPanel: false,
      workorder: this.props.workorder,
      status: "",
      priority: "",
      WOpriority: "",
      location: "",
      Eststartdate: null,
      Estenddate: null,
      befpicreq: false,
      aftpicreq: false,
      riskassessment: false,
      isWorkorderOvertime: false,
      WOComments: "",
      comments: "",
      assetSelectionMode: "",
      updating: false,
      showAlert: false,
      alertMessage: "",
      itemID: "",
      historyTitle: "",
      AssetChanged: false,
      locationLatLong: "",
      Assets: [],
      defaultAsset: "",
    };
  }

  public async componentDidMount() {
    if (this.props.showModal) {
      this.loadData();
    }
  }

  public async componentDidUpdate(prevProps: any) {
    if (!prevProps.showModal && this.props.showModal) {
      this.loadData();
    }
  }

  public async loadData() {
    await Promise.all([
      this._getworkTypeDataAsync(),
      this._getDepartmentData(),
      this._getTeamDataAsync(),
      this.getUserdata(),
      this._getContractors(),
      this._getInspectionFormsData(),
      this._getPrioritiesData()
    ]);

    this.setState({ loading: true });
    let statustext = this.props.workorder.workorderStatusId
      ? { id: this.props.statusId, text: this.props.statustext }
      : "";
    let prioritytext = this.props.priorityId
      ? { id: this.props.priorityId, text: this.props.priority }
      : "";
    let Assets: any[] = [];
    let assetGeometryArray: any[] = [];
    if (this.props.AssetDetails && this.props.AssetDetails.length > 0) {
      this.props.AssetDetails.forEach((element: any) => {
        this._getFCRData(
          this.props.AssetDetails && this.props.AssetDetails[0].maintenanceType
        );
        Assets.push({
          AssetName: element.AssetName,
          AssetNumber: element.AssetNumber,
          AssetGeom: element.geometry,
          AssetID: element.erpAssetId,
          erpAssetId: element.erpAssetId,
          AssetType: element.AssetType,
        });
        let assetGeometry = JSON.parse(element.geometry);
        let type = "";
        let polygoncoordinates: any[] = [];

        if (assetGeometry?.geometries && assetGeometry?.geometries.length > 0) {
          assetGeometry.geometries.forEach((element: any) => {
            if (element.type === "MultiPolygon") {
              type = element.type;
              if (element.coordinates && element.coordinates[0].length > 0) {
                element.coordinates[0][0].forEach((coordinate: any) => {
                  polygoncoordinates.push({
                    lat: coordinate[1],
                    lng: coordinate[0],
                  });
                });
              }
            } else if (element.type === "Point") {
              type = element.type;
              polygoncoordinates.push(
                element.coordinates[0],
                element.coordinates[1]
              );
            }
          });
          assetGeometryArray.push({
            type: type,
            coordinates: polygoncoordinates,
          });
        } else if (assetGeometry?.type === "Point") {
          assetGeometryArray.push(assetGeometry);
        }
        // console.log('assetGeometryArray', assetGeometryArray);
        //let latLong = { lat: assetGeometry.coordinates[1], lng: assetGeometry.coordinates[0] };
      });
    }
    this.setState({
      location: this.props.workorder.workorderLocation,
      locationLatLong: this.props.workorder.locationLatLong,
      assetGeometry: assetGeometryArray,
      status: statustext ? statustext : "",
      InspectionForm: {
        id: this.props.workorder?.inspectionFormId,
        text: this.props.workorder?.inspectionFormTitle,
      },
      previousstatus: statustext ? statustext : "",
      WOpriority: prioritytext ? prioritytext : "",
      WOComments: this.props.workorder.comments
        ? this.props.workorder.comments
        : "",
      aftpicreq: this.props.workorder.photoRequiredAfter ? true : false,
      befpicreq: this.props.workorder.photoRequiredBefore ? true : false,
      riskassessment: this.props.workorder.riskAssessmentRequired
        ? true
        : false,
      isWorkorderOvertime: this.props.workorder.isWorkorderOvertime
        ? true
        : false,
      Eststartdate: this.props.workorder.dateScheduledStart
        ? new Date(this.props.workorder.dateScheduledStart)
        : null,
      Estenddate: this.props.workorder.dateScheduledEnd
        ? new Date(this.props.workorder.dateScheduledEnd)
        : null,
      itemID: this.props.workorder.id,
      historyTitle: this.props.workorder.id,
      comments: "",
      description: this.props.workorder.description,
      Assets: Assets,
      assetType: Assets ? Assets[0]?.AssetType : "",
      StatusItems: this.props.StatusItems,
      // priorityItems: this.props.priorityItems,
      // priorityId: this.props.workorder?.priorityId,
      // priorityText: this.props.workorder?.priority,
      workorder: this.props.workorder,
      loading: false,
    });
  }
  private async _getTeamDataAsync() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.TeamList}?showAll=true`
      );
      const teamsItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id,
          text: r.title,
          departmentId: r.departmentId,
        }));
      const sortedItems = utility._sortItems(teamsItems, "text", false);
      let filteredteam = teamsItems
        .filter((item: any) => item.id === this.props.workorder.teamId)
        .map((item: any) => ({
          id: item.id,
          text: item.text,
          departmentId: item.departmentId,
        }));

      let filteredcrew = this.props.crewItems
        .filter(
          (item: any) =>
            item.departmentId === this.props.workorder.departmentId &&
            item.teamId === this.props.workorder.teamId &&
            item.id === this.props.workorder.crewId
        )
        .map((item: any) => ({
          id: item.id,
          text: item.text,
          departmentId: item.departmentId,
          teamId: item.teamId,
        }));
      this.setState({
        WOcrew: filteredcrew ? filteredcrew[0] : "",
        team: filteredteam ? filteredteam[0] : "",
        teamsItems: sortedItems,
        tempTeamItems: sortedItems,
        tempCrewItems: this.props.crewItems,
        allCrewItems: this.props.crewItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getDepartmentData() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.DepartmentList}?showAll=true`
      );
      const departmentItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({ id: r.id, text: r.title }));
      const sortedItems = utility._sortItems(departmentItems, "text", false);
      let filtereddepartment = sortedItems
        .filter((item: any) => item.id === this.props.workorder.departmentId)
        .map((item: any) => ({
          id: item.id,
          text: item.text,
        }));
      this.setState({
        alldepartmentItems: sortedItems,
        departmentItems: sortedItems,
        department: filtereddepartment ? filtereddepartment[0] : "",
      });
    } catch (error) {
      console.error(error);
    }
  }

  private async _getPrioritiesData() {
    try {
      const data: any = await utility.genericGetAPICall(`${Config.PriorityListName}?showAll=true`);
        const priorityItemsData = data
          .filter((r: any) => r.type === 'WorkOrder')
          .map((r: any) => ({ id: r.id, text: r.title }));
          const matchedPriorityItem = priorityItemsData.filter((item: any) => item.id === this.props.workorder.priorityId);
        const sortedItems = utility._sortItems(priorityItemsData, "text", false);
        this.setState({
          priorityItems: sortedItems,
          priority: matchedPriorityItem,
        });
      
    } catch (err) {
      console.log(err);
    }
  }
  private processFCRCode(codes: any, FailureItems: any[], CauseItems: any[]) {
    const category = codes.code + " - " + codes.title;

    if (codes.failureSubCodes) {
      codes.failureSubCodes.forEach((subCode: any) => {
        FailureItems.push({
          Category: category,
          ID: subCode.id,
          Name: codes.code + "," + subCode.code + " - " + subCode.title,
        });
        if (subCode.isDefault === true) {
          failure = {
            id: subCode.id,
            text: codes.code + "," + subCode.code + " - " + subCode.title,
            isDefault: codes.isDefault,
          };
        }
      });
    }

    if (codes.causeSubCodes) {
      codes.causeSubCodes.forEach((causeSubCode: any) => {
        CauseItems.push({
          Category: category,
          ID: causeSubCode.id,
          Name:
            codes.code + "," + causeSubCode.code + " - " + causeSubCode.title,
          isDefault: causeSubCode.isDefault,
        });
        if (causeSubCode.isDefault === true) {
          cause = {
            id: causeSubCode.id,
            text: codes.code + "," + causeSubCode.code + " - " + causeSubCode.title,
            isDefault: codes.isDefault,
          };
        }
      });
    }
  }
  private async _getFCRData(maintenanceType: string) {
    this.setState({ FCRLoading: true });
    const api = Config.FcrCategorisation + maintenanceType;
    const FailureItems: any[] = [];
    const CauseItems: any[] = [];
    const RemedyItems: any[] = [];
    try {
      const data: any = await utility.genericGetAPICallForList(api);
      if (data.failureCodes !== null) {
        data.failureCodes.forEach((failureCode: any) => {
          this.processFCRCode(failureCode, FailureItems, CauseItems);
        });

        data.causeCodes.forEach((causeCode: any) => {
          this.processFCRCode(causeCode, FailureItems, CauseItems);
        });
        let remedy;
        data.remedyCodes.forEach((remedyCode: any) => {
          RemedyItems.push({
            id: remedyCode.id,
            text: remedyCode.code + " - " + remedyCode.title,
            isDefault: remedyCode.isDefault,
          });
          if (remedyCode.isDefault === true) {
            remedy = {
              id: remedyCode.id,
              text: remedyCode.code + " - " + remedyCode.title,
              isDefault: remedyCode.isDefault,
            };
          }
        });

        const fromUngroupedData = new DataSource({
          store: {
            type: "array",
            data: FailureItems,
            key: "ID",
          },
          group: "Category",
        });

        const fromCauseUngroupedData = new DataSource({
          store: {
            type: "array",
            data: CauseItems,
            key: "ID",
          },
          group: "Category",
        });
        const Cause = CauseItems.filter((item: any) => item.isDefault).map(
          (r: any) => r.ID
        );
        this.setState({
          Failure: this.props.workorder.failureCodeId
            ? this.props.workorder.failureCodeId
            : "",
          Remedy: this.props.workorder.remedyCodeId
            ? {
                id: this.props.workorder.remedyCodeId,
                text: this.props.workorder.remedyCodeTitle,
              }
            : remedy,
          Cause: this.props.workorder.causeCodeId
            ? this.props.workorder.causeCodeId
            : Cause[0]
            ? Cause[0].ID
            : "",
          FailureItems: fromUngroupedData,
          CauseItems: fromCauseUngroupedData,
          RemedyItems,
          FCRLoading: false,
        });
      } else {
        this.setState({
          alertMessage:
            "FCR is not available for this Asset. Please choose different Asset.",
          showAlert: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  private async _getInspectionFormsData() {
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.FormTypes}?showAll=true`
      );
      const formItems = data.map((r: any) => ({
        id: r.id,
        text: r.title,
      }));
      const sortedItems = utility._sortItems(formItems, "text", false);
      this.setState({
        InspectionFormItems: sortedItems,
      });
    } catch (err) {
      console.log(err);
    }
  }
  public update() {
    if (!this.state.WOcrew) {
      this.setState({
        alertMessage: "Please Enter Crew/Team",
        showAlert: true,
      });
      return;
    }
    if (!this.state.Eststartdate) {
      this.setState({
        alertMessage: "Please select Schedule Start Date",
        showAlert: true,
      });
      return;
    }
    if (!this.state.WRTType) {
      this.setState({
        alertMessage: "Please select Work Order Type",
        showAlert: true,
      });
      return;
    }
    if (this.state.FailureItems && !this.state.Failure) {
      this.setState({
        alertMessage: "Please select Failure",
        showAlert: true,
      });
      return;
    }
    if (this.state.CauseItems && !this.state.Cause) {
      this.setState({
        alertMessage: "Please select Cause",
        showAlert: true,
      });
      return;
    }
    if (this.state.RemedyItems && !this.state.Remedy) {
      this.setState({
        alertMessage: "Please select Remedy",
        showAlert: true,
      });
      return;
    }

    if (!this.state.Estenddate) {
      this.setState({
        alertMessage: "Please select Schedule End Date",
        showAlert: true,
      });
      return;
    }
    if (!this.state.status) {
      this.setState({
        alertMessage: "Please select status",
        showAlert: true,
      });
      return;
    }
    if (!this.state.priority) {
      this.setState({
        alertMessage: "Please select priority",
        showAlert: true,
      });
      return;
    }
    if (!this.state.location) {
      this.setState({
        alertMessage: "Please enter Location",
        showAlert: true,
      });
      return;
    }
    if (this.state.Eststartdate && this.state.Estenddate) {
      var dfrom = new Date(this.state.Eststartdate);
      var dTo = new Date(this.state.Estenddate);
      if (dTo.getTime() < dfrom.getTime()) {
        this.setState({
          alertMessage:
            "Schedule End Date/time should be greater than Schedule Start Date/time",
          showAlert: true,
        });
        return;
      }
    }
    if (!this.state.Assets) {
      this.setState({
        alertMessage: "Please select an Asset",
        showAlert: true,
      });
      return;
    }
    if (!this.state.description) {
      this.setState({
        alertMessage: "Please provide Description.",
        showAlert: true,
      });
      return;
    }
    this.setState({ updating: true });
    this.setState({
      alertMessage: <Spinner label="Updating work order" />,
      hideAlertButton: true,
      showAlert: true,
    });
    let councilContactUserFullName = this.state.councilContactUserFullName;
    let councilContactUserId = null;
    if (this.state.councilContactUserFullName) {
      let startIndex = councilContactUserFullName.indexOf("(");
      let endIndex = councilContactUserFullName.indexOf(")");
      if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
        councilContactUserId = this.state.councilContactUserFullName
          .split("(")[1]
          .split(")")[0];
        councilContactUserFullName =
          this.state.councilContactUserFullName.split("(")[0];
      }
    }
    let dataToPassToService: any = {};

    dataToPassToService.id = this.state.itemID;
    dataToPassToService.location = this.state.location;
    dataToPassToService.workorderStatusId = this.state.status.id;
    dataToPassToService.priorityId = this.state.priority.id;
    dataToPassToService.inspectionFormId = this.state.InspectionForm
      ? this.state.InspectionForm.id
      : "";
    dataToPassToService.description = this.state.description;
    dataToPassToService.crewId = this.state.WOcrew ? this.state.WOcrew.id : -1;
    dataToPassToService.dateScheduledStart = this.state.Eststartdate
      ? new Date(this.state.Eststartdate)
      : null;
    dataToPassToService.dateScheduledEnd = this.state.Estenddate
      ? new Date(this.state.Estenddate)
      : null;
    dataToPassToService.photoRequiredAfter = this.state.aftpicreq;
    dataToPassToService.photoRequiredBefore = this.state.befpicreq;
    dataToPassToService.contractorId = this.state.contractor
      ? this.state.contractor.id
      : "";
    dataToPassToService.workorderStatusId = this.state.status.id;
    dataToPassToService.tenantId = Config.tenantId;
    dataToPassToService.riskAssessmentRequired = this.state.riskassessment;
    dataToPassToService.isWorkorderOvertime = this.state.isWorkorderOvertime;
    dataToPassToService.teamId = this.state.team.id;
    dataToPassToService.workorderTypeId = this.state.WRTType.id;
    dataToPassToService.leadingHandId = this.state.leadinghand
      ? this.state.leadinghand.id
      : null;
    dataToPassToService.departmentId = this.state.department.id;
    dataToPassToService.failureCodeId = this.state.Failure?.ID;
    dataToPassToService.causeCodeId = this.state.Cause?.ID;
    dataToPassToService.remedyCodeId = this.state.Remedy?.id;
    dataToPassToService.councilContactUserFullName =
      councilContactUserFullName.trim();
    dataToPassToService.councilContactUserId = councilContactUserId;
    dataToPassToService.councilContactPhone = this.state.councilContactPhone;
    dataToPassToService.councilContactEmail = this.state.councilContactEmail;
    dataToPassToService.locationLatLong = {
      x: this.state.latLong ? this.state.latLong.lng : this.state.locationLatLong ? this.state.locationLatLong.x : null,
      y: this.state.latLong ? this.state.latLong.lat : this.state.locationLatLong ? this.state.locationLatLong.y : null,
    };
    dataToPassToService.updated = new Date();
    dataToPassToService.updatedBy = this.props.currentUser.Email;
    if (this.state.Assets && this.state.Assets.length > 0) {
      // if (this.state.assetSelectionMode === "SearchAssetList") {
      //   dataToPassToService.assetIds = this.state.Assets.map((asset: any) => asset.AssetID);
      // }
      // //Map
      // if (this.state.assetSelectionMode === "MapList") {
      //   dataToPassToService.erpAssetId = this.state.Assets.Assetic_ID ? this.state.Assets.Assetic_ID : '';
      // }
      dataToPassToService.erpAssetIds = this.state.Assets.map((asset: any) =>
        asset.erpAssetId ? asset.erpAssetId : asset.AsseticAssetID
      );
    }
    utility
      .genericUpdateAPICall(
        Config.UpdateWO,
        this.state.itemID,
        dataToPassToService
      )
      .then(
        (results: any) => {
          if (this.state.Assets && this.state.AssetChanged) {
            if (
              (this.props.workorder.workRequestId !== null ||
                this.props.workorder.workRequestId !== "") &&
              this.props.workorder.workorderTypeId !== this.state.WRTType.id
            ) {
              this.updateWR();
            } else {
              this.updateHistoryAsset();
            }
          } else {
            this.setState({
              hideAlertButton: false,
              alertMessage: "Work Order has been updated",
              showAlert: true,
            });
            this.props.onClick();
          }
        },
        (err: any) => {
          console.log(err);
          this.setState({
            alertMessage: "An error occurred. Unable to update details.",
            showAlert: true,
            hideAlertButton: false,
          });
        }
      );
  }
  public async getUserdata() {
    let dataToPassToService: any = {};
    let selectedleadingHand = "";
    try {
      const data: any = await utility.genericGetAPICallForList(
        Config.SearchEmployeeApi,
        "POST",
        dataToPassToService
      );
      let _items = [];
      let _councilContactItems = [];
      _items = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => ({
          id: r.id,
          text: r.fullName,
          departmentId: r.departmentId,
          crewId: r.crewId,
          teamId: r.teamId,
        }));
      _councilContactItems = data
        .filter((r: any) => r.isActive === true)
        .map((r: any) => r.fullName);
      if (this.props.workorder.leadingHandId) {
        selectedleadingHand = _items
          .filter((r: any) => r.id === this.props.workorder.leadingHandId)
          .map((r: any) => ({
            id: r.id,
            text: r.fullName,
            departmentId: r.departmentId,
            crewId: r.crewId,
            teamId: r.teamId,
          }));
      }
      _items = utility._sortItems(_items, "text", false);

      this.setState({
        useritems: _items,
        callAfterInit: true,
        loading: false,
        alluseritems: _items,
        councilContactItems: _councilContactItems,
        leadinghand:
          selectedleadingHand && selectedleadingHand.length > 0
            ? selectedleadingHand[0]
            : "",
      });
    } catch (err) {
      console.log(err);
    }
  }
  private async _getContractors() {
    this.setState({ loading: true });
    try {
      let data: any = await utility.genericGetAPICall(
        Config.GetContractorsWithDetails
      );
      let _items = [];

      const mapContractor = (r: any) => ({
        id: r.id,
        text: r.businessName,
        email: r.email,
        teamID: r.teamIds,
        WorkTypes: r.workorderTypes,
        workorderTypeIds: r.workorderTypeIds,
        isActive: !!r.isActive,
        councilContactEmail: r.councilContactEmail,
        councilContactPhone: r.councilContactPhone,
        councilContactUserFullName:
          r.councilContactUserFullName + " (" + r.councilContactUserId + ")",
        councilContactUserId: r.councilContactUserId,
      });
      _items = data.filter((f: any) => !f.isDeleted).map(mapContractor);
      if (this.props.workorder && this.props.workorder.workorderTypeId) {
        _items = [];
        data.forEach((r: any, index: any) => {
          if (
            r.workorderTypeIds &&
            r.workorderTypeIds.includes(this.props.workorder.workorderTypeId)
          ) {
            _items.push(mapContractor(r));
          }
        });
      }
      let selectedContractor = [];
      if (this.props.workorder && this.props.workorder.contractorId) {
        selectedContractor = _items.filter(
          (f: any) => f.id === this.props.workorder.contractorId
        );
      }
      this.setState({
        contractorItems: _items,
        callAfterInit: true,
        loading: false,
        allcontractorItems: _items,
        contractor:
          selectedContractor && selectedContractor.length > 0
            ? selectedContractor[0]
            : "",
        councilContactEmail:
          this.props.workorder.councilContactEmail &&
          this.props.workorder.councilContactEmail !== null
            ? this.props.workorder.councilContactEmail
            : selectedContractor && selectedContractor.length > 0
            ? selectedContractor[0].councilContactEmail
            : "",
        councilContactPhone:
          this.props.workorder.councilContactPhone &&
          this.props.workorder.councilContactPhone !== null
            ? this.props.workorder.councilContactPhone
            : selectedContractor && selectedContractor.length > 0
            ? selectedContractor[0].councilContactPhone
            : "",
        councilContactUserFullName:
          this.props.workorder.councilContactUserFullName &&
          this.props.workorder.councilContactUserFullName !== null
            ? this.props.workorder.councilContactUserFullName
            : selectedContractor &&
              selectedContractor.length > 0 &&
              selectedContractor[0].councilContactUserFullName !== null
            ? selectedContractor[0].councilContactUserFullName
            : "",
      });
    } catch (err) {
      console.log(err);
    }
  }
  public updateWR() {
    let currentDateTime = new Date();
    let dataToPassToService: any = {};
    dataToPassToService.workRequestTypeId =
      this.state.WRTType.workRequestTypeId;
    dataToPassToService.updated = currentDateTime.toISOString();
    dataToPassToService.updatedBy = this.currentUser.Email;
    dataToPassToService.id = this.props.workorder.workRequestId;
    utility
      .genericUpdateAPICall(
        Config.WRList,
        this.props.workorder.workRequestId,
        dataToPassToService
      )
      .then(
        (results: any) => {
          this.updateHistoryAsset();
        },
        (err: any) => {
          console.log(err);
          this.setState({
            alertMessage: "An error occoured. Unable to update details.",
            showAlert: true,
            hideAlertButton: false,
          });
        }
      );
  }
  public updateHistoryAsset() {
    let currentDateTime = new Date();
    let dataToPassToServiceForHistory: any = {};
    dataToPassToServiceForHistory.statusId = "8";
    dataToPassToServiceForHistory.createdBy = this.currentUser.Email;
    dataToPassToServiceForHistory.updatedBy = this.currentUser.Email;
    dataToPassToServiceForHistory.comments = "Asset changed";
    dataToPassToServiceForHistory.tenantId = Config.tenantId;
    dataToPassToServiceForHistory.workorderId = this.state.itemID;
    dataToPassToServiceForHistory.created = currentDateTime.toISOString();
    dataToPassToServiceForHistory.updated = currentDateTime.toISOString();
    dataToPassToServiceForHistory.dateRecordCreatedInDb =
      currentDateTime.toISOString();
    utility
      .genericPostAPIcall(
        Config.workorderHistoryList,
        dataToPassToServiceForHistory
      )
      .then(
        (data: any) => {
          this.setState({
            alertMessage: "Work Order has been updated with Asset.",
            showAlert: true,
            hideAlertButton: false,
            previousPage: true,
          });
          this.props.onClick();
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  public alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this._closeDialog()}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }
  private _closeDialog = (): void => {
    this.setState({ alertMessage: "", showAlert: false });
  };
  private handleValueChange = (event: any, fieldName: string) => {
    if (fieldName === "Eststartdate" && event.value) {
      let endDate = new Date(event.value);
      endDate.setHours(event.value.getHours() + 1);
      this.setState({
        Estenddate: new Date(endDate),
      });
    }

    this.setState({
      [fieldName]: event.value,
    });
  };
  public handleautoCompletechange(e: any, fieldName: string) {
    this.setState({ [fieldName]: e.value });
  }
  public handleDropdownChange(e: any, fieldName: string) {
    if (fieldName === "WRTType" && e.selectedItem !== null) {
      this.setState({
        departmentItems: [],
        tempCrewItems: [],
        department: "",
        team: "",
        crewteam: "",
      });
      const selectedDeptItem = this.state.alldepartmentItems
        .filter((r: any) => r.id === e.selectedItem.departmentId)
        .map((r: any) => ({ id: r.id, text: r.text }));
      let filteredTeam: any = [];
      this.state.tempTeamItems.forEach((element: any) => {
        if (
          e.selectedItem?.teamId &&
          e.selectedItem?.teamId.some((g: any) => g === element.id)
        ) {
          filteredTeam.push(element);
        }
      });
      let filteredCrew: any = [];
      this.state.allCrewItems &&
        this.state.allCrewItems.forEach((element: any) => {
          if (
            e.selectedItem?.teamId &&
            e.selectedItem?.teamId.some(
              (g: any) =>
                g === element.teamId &&
                element.departmentId === e.selectedItem.departmentId
            )
          ) {
            filteredCrew.push(element);
          }
        });
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      let layers = e.selectedItem?.gisLayers!;
      let layersTpPass = "";
      if (layers) {
        layersTpPass = layers.layers.join();
      }
      this.setState({
        departmentItems: selectedDeptItem,
        befpicreq: Boolean(e.selectedItem.befpicreq),
        aftpicreq: Boolean(e.selectedItem.aftpicreq),
        riskassessment: Boolean(e.selectedItem.riskassessment),
        teamsItems: filteredTeam,
        tempCrewItems: filteredCrew,
        team: filteredTeam && filteredTeam.length === 1 ? filteredTeam[0] : "",
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
                (c: any) =>
                  c.departmentId === e.selectedItem.departmentId &&
                  c.teamId === e.selectedItem.teamId
              ),
        department:
          selectedDeptItem && selectedDeptItem.length === 1
            ? selectedDeptItem[0]
            : "",
        al: layersTpPass,
      });
    }
    if (fieldName === "department" && e.selectedItem !== null) {
      const { tempTeamItems } = this.state;
      this.setState({
        tempCrewItems: [],
        teamsItems: [],
        team: "",
        crew: "",
      });
      const filteredTeam = tempTeamItems.filter(
        (t: any) => t.departmentId === e.selectedItem?.id
      );
      let filteredCrew: any = "";
      if (filteredTeam && filteredTeam.length === 1) {
        filteredCrew = this.state.allCrewItems.filter(
          (c: any) =>
            c.teamId === filteredTeam[0]?.id &&
            c.departmentId === e.selectedItem?.id
        );
      }
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      this.setState({
        teamsItems: filteredTeam,
        team: filteredTeam && filteredTeam.length === 1 ? filteredTeam[0] : "",
        tempCrewItems: filteredCrew ? filteredCrew : [],
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
                (c: any) =>
                  c.departmentId === e.selectedItem.departmentId &&
                  c.teamId === e.selectedItem.teamId
              ),
      });
      setTimeout(() => this.setState({ loading: false }), 1000);
    }
    if (fieldName === "team" && e.selectedItem !== null) {
      const { allCrewItems, department } = this.state;
      this.setState({
        tempCrewItems: [],
        crew: "",
      });
      const filteredCrew = allCrewItems.filter(
        (c: any) =>
          c.teamId === e.selectedItem?.id && c.departmentId === department.id
      );
      let filteredUsers: any = "";
      if (filteredCrew.length === 1) {
        filteredUsers = this.state.alluseritems.filter(
          (c: any) => c.crewId === filteredCrew[0].id
        );
      }
      this.setState({
        disableCrew: false,
        tempCrewItems: filteredCrew,
        alltempCrewItems: filteredCrew,
        crewteam:
          filteredCrew && filteredCrew.length === 1 ? filteredCrew[0] : "",
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
                (c: any) =>
                  c.departmentId === e.selectedItem.departmentId &&
                  c.teamId === e.selectedItem?.id
              ),
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
      });
    }
    if (fieldName === "crewteam" && e.selectedItem !== null) {
      const { alluseritems } = this.state;
      this.setState({
        useritems: [],
        leadinghand: "",
      });
      const filteredUsers = alluseritems.filter(
        (c: any) => c.crewId === e.selectedItem?.id
      );

      this.setState({
        disableleadinghand: false,
        useritems:
          filteredUsers && filteredUsers.length > 0
            ? filteredUsers
            : this.state.alluseritems.filter(
                (c: any) =>
                  c.departmentId === e.selectedItem.departmentId &&
                  c.teamId === e.selectedItem?.id
              ),
        leadinghand:
          filteredUsers && filteredUsers.length === 1 ? filteredUsers[0] : "",
      });
    }
    if (fieldName === "contractor") {
      let selectedStatus = this.state.StatusItems.filter(
        (d: any) => d.id === global.withContractorStatusId
      );
      this.setState({
        status: selectedStatus ? selectedStatus[0] : "",
        councilContactEmail: e.selectedItem.councilContactEmail,
        councilContactPhone: e.selectedItem.councilContactPhone,
        councilContactUserFullName: e.selectedItem.councilContactUserFullName,
      });
    }
    if (fieldName === "Failure" || fieldName === "Cause") {
      this.setState({ [fieldName]: e.selectedItem.ID });
    } else {
      this.setState({ [fieldName]: e.selectedItem });
    }
  }
  private SearchClicked() {
    this.setState({
      iFramePanelOpened: true,
      selectModeMap: true,
      selectParent: false,
    });
  }
  public removeAssetNumber(index: any) {
    // Check if the index is valid
    if (index >= 0 && index < this.state.Assets.length) {
      // Create a new array of assets excluding the one at the specified index
      const updatedAssets: any = [...this.state.Assets];
      updatedAssets.splice(index, 1);

      // Check if the removed asset was the last one
      if (updatedAssets.length === 0) {
        let endDate = new Date();
        endDate.setHours(endDate.getHours() + 1);
        this.setState({
          Assets: [],
          defaultAsset: "",
          WRTType: "",
          department: "",
          crewteam: "",
          location: "",
          assetlocation: "",
          Eststartdate: new Date(),
          Estenddate: new Date(endDate),
          befpicreq: false,
          aftpicreq: false,
          riskassessment: false,
          isWorkorderOvertime: false,
          team: "",
          Cause: "",
          Remedy: "",
          InspectionForm: "",
          leadinghand: "",
          description: "",
          status: "",
          priority: "",
          statusItems: this.state.StatusItems,
          WRTTypeItems: this.state.allWRTTypeItems,
          teamItems: this.state.tempTeamItems,
          departmentItems: this.state.alldepartmentItems,
          tempCrewItems: this.state.crewItems,
          Failure: "",
          FailureItems: [],
          CauseItems: [],
          RemedyItems: [],
          InspectionFormItems: this.state.InspectionFormItems,
        });
      } else {
        // Update the state with the new array of assets
        this.setState({
          Assets: updatedAssets,
        });
      }
    }
  }

  public async selectAssets(selectedAssetArray: any) {
    if (!Array.isArray(selectedAssetArray)) {
      selectedAssetArray = [selectedAssetArray];
    }
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.AssetType);
      const allSameType = assetTypes.every(
        (type: string, _: any, array: string[]) => type === array[0]
      );
      if (!allSameType) {
        this.setState({
          alertMessage:
            "The selected assets do not all have the same asset type. Please select assets of the same type.",
          showAlert: true,
        });
        return;
      }
    }
    
      const selectedAssets: any = [...this.state.Assets];
      let isFirstAsset: boolean = selectedAssets.length === 0;
      const initialAssetType = this.state.assetType;
      let duplicateAssets: any [] = [];

      for (let i = 0; i < selectedAssetArray.length; i++) {
        let Asset = selectedAssetArray[i];
      // check for duplicates

      if (isFirstAsset) {
        const chosenAssetType = Asset.AssetType;
        const chosenAssetypeID = Asset.erpAssetId;
        try {
          await this._getworkTypeDataAsync(
            Asset.workGroupIds,
            Asset.accntSystemId,
            Asset.activityId,
            Asset.taskId
          );
        } catch (error) {
          this.setState({
            alertMessage:
              `Work Group is not available for this Asset ${chosenAssetypeID}. Please choose different Asset.`,
            showAlert: true,
          });
          return;
        }
        
        this._getFCRData(Asset.maintenanceAssetType);
        selectedAssets.push(Asset);
        this.setState({
          defaultAsset: Asset,
          Assets: selectedAssets,
          assetType: chosenAssetType,
          Address: Asset.Location,
          location: Asset.Location,
          latLong: Asset.coordinates,
          mode: "SearchAssetList",
          showPanel: false,
        });
        isFirstAsset = false;
      } else {

        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) => (existingAsset.erpAssetId === Asset.erpAssetId) || (existingAsset.AsseticAssetID == Asset.erpAssetId)
        );
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.erpAssetId);
          this.setState({
            alertMessage: `Asset ID ${duplicateAssets} is already added. Please select a different Asset`,
            showAlert: true,
          });
          continue;
        }

        const assetType = this.state.assetType; // Get the stored assetType
        if (Asset.AssetType === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset);
          this.setState({
            Assets: selectedAssets,
            mode: "SearchAssetList",
            showPanel: false,
          });
        } else {
          this.setState({
            alertMessage:
              `The selected asset does not match the asset type of the initially selected asset. You can only select assets of the same type (${assetType}). Please choose a different asset.`,
            showAlert: true,
          });
          continue; // skip to next Asset
        }
      }
    }
    this.setState({ showPanel: false });
  }

  private async _getworkTypeDataAsync(
    workGroupIds?: any,
    projectIds?: any,
    activitys?: any,
    taskIds?: any
  ) {
    this.setState({ workTypeloading: true });
    try {
      const data: any = await utility.genericGetAPICall(
        `${Config.WTlistname}?showAll=true`
      );
      let filtereddata;
      let newValues: any = [];
      let selectedValues: any = "";
      const assetDetails = this.props.AssetDetails;
      const workGroupId =
        workGroupIds !== undefined
          ? workGroupIds
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].workGroupIds
          : "";
      const projectId =
        projectIds !== undefined
          ? projectIds
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].accntSystemId
          : "";
      const taskId =
        taskIds !== undefined
          ? taskIds
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].taskId
          : "";
      const activity =
        activitys !== undefined
          ? activitys
          : assetDetails && assetDetails.length > 0
          ? assetDetails[0].activityId
          : "";
      let isActivity: boolean = false;
      filtereddata = workGroupId
        ? data.filter((f: any) => {
            const hasWorkGroupIds = f.workGroupIds && f.workGroupIds.length > 0;
            const hasMatchingWorkGroup =
              hasWorkGroupIds &&
              f.workGroupIds.some((value: any) => workGroupId.includes(value));
            const isMatchingCategory = f.category === global.filterWOType;
            const hasMatchingProject = projectId
              ? f.projectId === projectId
              : true;
            const hasMatchingActivity = activity
              ? f.activity === activity
              : true;
            const hasMatchingTask = taskId ? f.taskId === taskId : true;
            if (workGroupId) {
              isActivity = hasMatchingWorkGroup && isMatchingCategory;
              if (projectId !== "" || projectId !== null) {
                isActivity = isActivity && hasMatchingProject;
              }
              if (activity !== "" || projectId !== null) {
                isActivity = isActivity && hasMatchingActivity;
              }
              if (taskId !== "" || projectId !== null) {
                isActivity = isActivity && hasMatchingTask;
              }
              return isActivity;
            } else {
              return isMatchingCategory;
            }
          })
        : data.filter((f: any) => f.category === global.filterWOType);
      const WRTTypeItems = filtereddata.map((item: any) => ({
        id: item.id,
        text: item.title,
        categoryId: item.workRequestTypeId,
        category: item.category,
        befpicreq: item.photoRequiredBefore ? true : false,
        aftpicreq: item.photoRequiredAfter ? true : false,
        riskassessment: item.riskAssessmentRequired ? true : false,
        departmentId: item.departmentId,
        teamId: item.teamIds,
        workGroupId: item.workGroupIds,
        workRequestTypeId: item.workRequestTypeId,
        disabled: item.isActive ? false : true,
        gisLayers: item.gisLayers,
      }));
      const sortedItems = utility._sortItems(WRTTypeItems, "text", false);
      if (sortedItems && sortedItems.length === 1) {
        const selectedeprt =
          this.state.alldepartmentItems &&
          this.state.alldepartmentItems.filter(
            (d: any) => d.id === sortedItems[0].departmentId
          );
        const selectedteam = this.state.tempTeamItems?.filter((d: any) =>
          sortedItems[0].teamId.includes(d.id)
        );
        const teamArray = selectedteam?.map((item: any) => item.id);
        const selectedcrew = this.props.crewItems.filter(
          (d: any) => teamArray?.includes(d.teamId)
        );
        const selectedWOType =
          sortedItems &&
          sortedItems.filter(
            (d: any) => d.id === this.props.workorder.workorderTypeId
          );
        let layers = sortedItems[0].gisLayers;
        let layersToPass = "";
        if (layers) {
          layersToPass = layers.layers.join();
        }
        if (selectedWOType.length <= 0) {
          const wotype: any =
            data &&
            data
              .filter((d: any) => d.id === this.props.workorder.workorderTypeId)
              .map((item: any) => ({
                id: item.id,
                text: item.title,
                categoryId: item.workRequestTypeId,
                category: item.category,
                befpicreq: item.photoRequiredBefore ? true : false,
                aftpicreq: item.photoRequiredAfter ? true : false,
                riskassessment: item.riskAssessmentRequired ? true : false,
                departmentId: item.departmentId,
                teamId: item.teamIds,
                workGroupId: item.workGroupIds,
                workRequestTypeId: item.workRequestTypeId,
                disabled: item.isActive ? false : true,
                gisLayers: item.gisLayers,
              }));
          newValues = [...wotype, sortedItems[0]];
          selectedValues =
            newValues &&
            newValues.filter(
              (d: any) => d.id === this.props.workorder.workorderTypeId
            );
        }

        this.setState({
          WRTType:
            selectedWOType.length > 0
              ? selectedWOType[0]
              : selectedValues.length > 0
              ? selectedValues[0]
              : "",
          WRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          allWRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          departmentItems: selectedeprt,
          department: selectedeprt ? selectedeprt[0] : "",
          teamsItems: selectedteam,
          team:
            selectedteam && selectedteam.length === 1 ? selectedteam[0] : "",
          tempCrewItems: selectedcrew,
          al: layersToPass,
          workTypeloading: false,
        });
      } else if (this.props.workorder.workorderTypeId) {
        const selectedWOType =
          sortedItems &&
          sortedItems.filter(
            (d: any) => d.id === this.props.workorder.workorderTypeId
          );
        if (selectedWOType.length <= 0) {
          const wotype: any =
            data &&
            data
              .filter((d: any) => d.id === this.props.workorder.workorderTypeId)
              .map((item: any) => ({
                id: item.id,
                text: item.title,
                categoryId: item.workRequestTypeId,
                category: item.category,
                befpicreq: item.photoRequiredBefore ? true : false,
                aftpicreq: item.photoRequiredAfter ? true : false,
                riskassessment: item.riskAssessmentRequired ? true : false,
                departmentId: item.departmentId,
                teamId: item.teamIds,
                workGroupId: item.workGroupIds,
                workRequestTypeId: item.workRequestTypeId,
                disabled: true,
              }));
          newValues = [...wotype, sortedItems[0]];
          selectedValues =
            newValues &&
            newValues.filter(
              (d: any) => d?.id === this.props.workorder.workorderTypeId
            );
        }
        this.setState({
          WRTType:
            selectedWOType.length > 0
              ? selectedWOType[0]
              : selectedValues.length > 0
              ? selectedValues[0]
              : "",
          WRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          allWRTTypeItems: selectedWOType.length <= 0 ? newValues : sortedItems,
          workTypeloading: false,
        });
      } else if (sortedItems.length <= 0) {
        this.setState({
          workTypeloading: false,
        });
        throw new Error(
          "Work Group is not available for this Asset. Please choose a different Asset."
        );
      }
    } catch (err) {
      console.log(err);
      this.setState({
        workTypeloading: false,
      });
      throw err;
    }
  }
  public async selectAssetsonMap(selectedAssetArray: any) {
    if (selectedAssetArray.length > 1) {
      // Extract asset types and check if they are all the same
      const assetTypes = selectedAssetArray.map((asset: any) => asset.type);
      const allSameType = assetTypes.every(
        (type: string, _: any, array: string[]) => type === array[0]
      );
      if (!allSameType) {
        this.setState({
          alertMessage:
            "The selected assets do not all have the same asset type. Please select assets of the same type.",
          showAlert: true,
        });
        return;
      }
    }

    let flatCoordinates: any = [];
    let isFirstAsset: boolean = this.state.Assets.length === 0;
    const selectedAssets: any = [...this.state.Assets];
    const initialAssetType = this.state.assetType;
    let duplicateAssets: any[] = [];

    for (let i = 0; i < selectedAssetArray.length; i++) {
      let Asset = selectedAssetArray[i];
      let AssetProperties = JSON.parse(Asset.properties);

      if (isFirstAsset) {
        const chosenAssetType = Asset.type;
          //COA-259 
          let workGroupString = AssetProperties?.Work_Groups.trim();
          workGroupString = workGroupString?.replace(/\\/g, "").replace(/'/g, '"');
          let workGroupsArray = [];

          try {
            workGroupsArray = JSON.parse(workGroupString);
        } catch (error) {
            console.error('Error parsing workGroupString:', error);
        }
        
        const workGroupValue = workGroupsArray.length > 0 ? workGroupsArray[0] : null;
        try {
          await this._getworkTypeDataAsync(
            workGroupValue,
            AssetProperties.Ext_Accounting_System_1_ID2,
            AssetProperties.activityId,
            AssetProperties.taskId
          );
        } catch (error) {
          this.setState({
            alertMessage: `Work Group is not available for this Asset ${Asset.id}. Please choose different Asset.`,
            showAlert: true,
          });
          return;
        }
        this._getFCRData(AssetProperties.Maintenance_Asset_Type);
        let flatCoordinatesArray = AssetProperties.geometry.flatCoordinates;
        flatCoordinatesArray.forEach((element: any, i: any) => {
          if (i % 2 === 0) {
            flatCoordinates.push({
              lat: flatCoordinatesArray[i + 1],
              lng: flatCoordinatesArray[i],
            });
          }
        });

        let Parseddata = JSON.parse(selectedAssetArray[0].properties);
        const assetType = this.state.assetType; // Get the stored assetType
        if (Asset.type === assetType) {
          selectedAssets.push(Asset);
          this.setState({
            defaultAsset: Asset,
            Assets: selectedAssets,
            assetSelectionMode: "MapList",
            lat: selectedAssetArray[0].center[1].toString(),
            lng: selectedAssetArray[0].center[0].toString(),
            latLong: flatCoordinates[0],
            location: Parseddata.Location ? Parseddata.Location : "",
            assetlocation: Parseddata.asset_location + " Adelaide SA Australia",
            iFramePanelOpened: false,
            selectParent: false,
            selectModeMap: false,
            AssetChanged: true,
          });
          isFirstAsset = false;
        }else {
          this.setState({
            alertMessage: `The selected asset does not match the asset type of the initially selected asset(${assetType}). You can only select assets of the same type. Please choose a different asset.`,
            showAlert: true,
            iFramePanelOpened: false,
          });
          return;
        }
      } else {
        // check for duplicates
        const isDuplicateAsset = selectedAssets.some(
          (existingAsset: any) =>
            existingAsset.AsseticAssetID === Asset.AsseticAssetID ||
            existingAsset.erpAssetId == Asset.AsseticAssetID
        );
        if (isDuplicateAsset) {
          duplicateAssets.push(Asset.AsseticAssetID);
          this.setState({
            alertMessage: `Asset ID ${duplicateAssets} is already added. Please select a different Asset`,
            showAlert: true,
          });
          continue; // Skip to next asset
        }

        // Check if the asset type matches the first asset
        const assetType = this.state.assetType; // Get the stored assetType
        if (Asset.type === assetType) {
          // If assetType matches, add the asset
          selectedAssets.push(Asset);
          this.setState({
            Assets: selectedAssets,
            mode: "MapList",
            iFramePanelOpened: false,
          });
        } else {
          this.setState({
            alertMessage: `The selected asset does not match the asset type of the initially selected asset(${assetType}). You can only select assets of the same type. Please choose a different asset.`,
            showAlert: true,
            iFramePanelOpened: false,
          });
          continue; // Skip to next asset
        }
      }
    }
    this.setState({ iFramePanelOpened: false });
  }

  public _onDismissMap() {
    this.setState({
      iFramePanelOpened: false,
    });
  }
  public renderAssetMap() {
    return (
      <AssetMap
        //lat={this.state.assetGeometry && this.state.assetGeometry[0].coordinates ? this.state.assetGeometry[0].coordinates[1] : 0 }
        //long={this.state.assetGeometry && this.state.assetGeometry[0].coordinates ? this.state.assetGeometry[0].coordinates[0] : 0 }
        coordinates={this.state.assetGeometry}
        al={this.state.al}
        polylines={false}
        selectAssets={(Asset: any) => this.selectAssetsonMap(Asset)}
        iFramePanelOpened={this.state.iFramePanelOpened}
        onClick={() => this._onDismissMap()}
        selectParent={this.state.selectParent}
        selectModeMap={this.state.selectModeMap}
      />
    );
  }

  public renderAssetPanel() {
    return (
      <div>
        <Dialog
          open={this.state.showPanel}
          onClose={() => this.setState({ showPanel: false })}
          fullWidth={true}
          maxWidth={"xl"}
          aria-labelledby="max-width-dialog-title"
        >
          <IconButton
            aria-label="close"
            onClick={() => this.setState({ showPanel: false })}
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
          <br />
          <br />
          <DialogContent dividers={true}>
            <ScrollView
              width="100%"
              height="100%"
              showScrollbar="onScroll"
              scrollByThumb={true}
            >
              <AssetSearch
                selectMode={true}
                onClick={(Asset: any) => this.selectAssets(Asset)}
                heading={false}
                hideAssetMapButton={true}
                selectWO={true}
                myhttpclient={this.props.myhttpclient}
                aadHttpClient={this.props.aadHttpClient}
                onAssetsSelected={(Asset: any) => this.selectAssets(Asset)}
              />
            </ScrollView>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
  public render(): React.ReactElement<any> {
    return (
      <div>
        {this.state.showAlert && this.alert()}
        {this.state.showPanel && this.renderAssetPanel()}
        {this.renderAssetMap()}
        <Dialog
          open={this.props.showModal}
          onClose={() => this.props.onClose()}
          fullWidth={true}
          maxWidth={"xl"}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">Edit Work Order</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => this.props.onClose()}
            className="closeIcon"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers={true}>
            <ScrollView
              width="100%"
              height="100%"
              showScrollbar="onScroll"
              scrollByThumb={true}
            >
              <div className="popContentDiv">
                {!this.state.workTypeloading ? (
                  <div>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Paper elevation={6} className={"assetPaper"}>
                          <Grid container spacing={1}>
                            <Grid item xs={1} sm={1} className={"AssetIconDiv"}>
                              <EmojiTransportationIcon
                                className={"AssetIcon"}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sm={4}
                              className={"AssetLable"}
                              onClick={() => this.setState({ showPanel: true })}
                            >
                              <p className={"AssetboxTitle"}>
                                Search Asset from List
                              </p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Paper
                          elevation={6}
                          className={"assetPaper"}
                          onClick={this.SearchClicked}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={1} sm={1} className={"AssetIconDiv"}>
                              <MapIcon className={"AssetIcon"} />
                            </Grid>
                            <Grid item xs={4} sm={4} className={"AssetLable"}>
                              <p className={"AssetboxTitle"}>
                                Search Asset from Map
                              </p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <br/>
                    <Grid container spacing={1}>
                      {this.state.Assets && this.state.Assets.length > 0 && (
                        <MuiAccordion style={{ width: "100%" }}>
                          <MuiAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={"classes.heading selectAssets"}>
                              Selected Assets
                            </Typography>
                          </MuiAccordionSummary>
                          <MuiAccordionDetails>
                            <Grid container spacing={1}>
                              {this.state.Assets.map(
                                (asset: any, index: number) => (
                                  <Grid item xs={12} sm={12} key={index}>
                                    <Paper elevation={12} className={"assetSelectPaper"}>
                                      <span className={""}>
                                        {asset.erpAssetId? asset.erpAssetId: asset.AssetID}, {" "}
                                        {asset.AssetName ? asset.AssetName : ""}
                                      </span>
                                      <span className="floatRight">
                                        <DeleteIcon
                                          className={"danger"}
                                          onClick={() =>
                                            this.removeAssetNumber(index)
                                          }
                                        />
                                      </span>
                                    </Paper>
                                  </Grid>
                                )
                              )}
                            </Grid>
                          </MuiAccordionDetails>
                        </MuiAccordion>
                      )}
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                      {this.state.WRTTypeItems &&
                        this.state.WRTTypeItems.length >= 0 && (
                          <Grid item xs={12} sm={6}>
                            <SelectBoxInput
                              stylingMode="underlined"
                              displayExpr="text"
                              valueExpr="id"
                              showClearButton={true}
                              label="Work Order Type"
                              items={
                                this.state.WRTTypeItems
                                  ? this.state.WRTTypeItems
                                  : ""
                              }
                              selectedItem={
                                this.state.WRTType ? this.state.WRTType : ""
                              }
                              onSelectionChanged={(e: any) =>
                                this.handleDropdownChange(e, "WRTType")
                              }
                              //disabled={this.state.WRTType.disabled}
                            />
                          </Grid>
                        )}
                      {this.state.departmentItems && (
                        <Grid item xs={12} sm={6}>
                          <SelectBoxInput
                            stylingMode="underlined"
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            label="Department *"
                            items={this.state.departmentItems}
                            selectedItem={this.state.department}
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "department")
                            }
                          />
                        </Grid>
                      )}
                      {this.state.teamsItems && (
                        <Grid item xs={12} sm={6}>
                          <SelectBoxInput
                            stylingMode="underlined"
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            items={this.state.teamsItems}
                            selectedItem={this.state.team}
                            label="Teams *"
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "team")
                            }
                          />
                        </Grid>
                      )}
                      {this.state.tempCrewItems && (
                        <Grid item xs={12} sm={6}>
                          <SelectBoxInput
                            stylingMode="underlined"
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            items={this.state.tempCrewItems}
                            selectedItem={this.state.WOcrew}
                            label="Crew *"
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "WOcrew")
                            }
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6}>
                        <SelectBoxInput
                          stylingMode="underlined"
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.contractorItems}
                          selectedItem={this.state.contractor}
                          disabled={
                            this.state.status &&
                            (this.state.status.id ===
                              global.contractorStartedStatus ||
                              this.state.status.id === global.returnStatusid ||
                              (this.state.previousstatus &&
                                this.state.previousstatus.id ===
                                  global.withContractorStatusId))
                              ? true
                              : false
                          }
                          label="Contractor"
                          onSelectionChanged={(e: any) =>
                            this.handleDropdownChange(e, "contractor")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectBoxInput
                          stylingMode="underlined"
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          label="Leading Hand"
                          items={this.state.useritems}
                          selectedItem={
                            this.state.leadinghand ? this.state.leadinghand : ""
                          }
                          onSelectionChanged={(e: any) =>
                            this.handleDropdownChange(e, "leadinghand")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateBoxInput
                          stylingMode="underlined"
                          label="Schedule Start Date *"
                          type="datetime"
                          displayFormat={"dd/MM/yy hh:mm a"}
                          showClearButton={true}
                          value={
                            this.state.Eststartdate
                              ? new Date(this.state.Eststartdate)
                              : null
                          }
                          onValueChanged={(e: any) =>
                            this.handleValueChange(e, "Eststartdate")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DateBoxInput
                          stylingMode="underlined"
                          label="Schedule End Date *"
                          value={
                            this.state.Estenddate
                              ? new Date(this.state.Estenddate)
                              : null
                          }
                          type="datetime"
                          displayFormat={"dd/MM/yy hh:mm a"}
                          showClearButton={true}
                          onValueChanged={(e: any) =>
                            this.handleValueChange(e, "Estenddate")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextAreaInput
                          label="Description *"
                          value={this.state.description}
                          onValueChanged={(e: any) =>
                            this.handleValueChange(e, "description")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextBoxInput
                          stylingMode="underlined"
                          label="Location *"
                          // pattern={"[A-Za-z]+"}
                          // minLength={2}
                          value={this.state.location}
                          onValueChanged={(e: any) =>
                            this.handleValueChange(e, "location")
                          }
                          disabled={false}
                        />
                      </Grid>
                      {this.state.priorityItems && (
                        <Grid item xs={12} sm={6}>
                          <SelectBoxInput
                            stylingMode="underlined"
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            items={this.state.priorityItems}
                            selectedItem={this.state.priority ? this.state.priority[0] : ""}
                            label="Priority *"
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "priority")
                            }
                          />
                        </Grid>
                      )}
                      {this.state.StatusItems && (
                        <Grid item xs={12} sm={6}>
                          <SelectBoxInput
                            stylingMode="underlined"
                            displayExpr="text"
                            valueExpr="id"
                            showClearButton={true}
                            items={this.state.StatusItems}
                            selectedItem={this.state.status}
                            label="Status *"
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "status")
                            }
                          />
                        </Grid>
                      )}
                      {!this.state.FCRLoading && (
                        <Grid item xs={12} sm={6}>
                          <SelectBox
                            stylingMode="underlined"
                            showClearButton={true}
                            label="Failure"
                            valueExpr="ID"
                            grouped={true}
                            displayExpr="Name"
                            dataSource={this.state.FailureItems}
                            // value={this.state.Failure}
                            disabled={this.state.Assets ? false : true}
                            defaultValue={this.state.Failure}
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "Failure")
                            }
                          />
                        </Grid>
                      )}
                      {!this.state.FCRLoading && (
                        <Grid item xs={12} sm={6}>
                          <SelectBox
                            stylingMode="underlined"
                            showClearButton={true}
                            label="Cause"
                            valueExpr="ID"
                            grouped={true}
                            displayExpr="Name"
                            dataSource={this.state.CauseItems}
                            // value={this.state.Cause}
                            defaultValue={
                              this.state.Cause ? this.state.Cause : ""
                            }
                            disabled={this.state.Assets ? false : true}
                            onSelectionChanged={(e: any) =>
                              this.handleDropdownChange(e, "Cause")
                            }
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <SelectBoxInput
                          stylingMode="underlined"
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.RemedyItems}
                          selectedItem={this.state.Remedy}
                          disabled={this.state.Assets ? false : true}
                          label="Remedy"
                          onSelectionChanged={(e: any) =>
                            this.handleDropdownChange(e, "Remedy")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectBoxInput
                          stylingMode="underlined"
                          displayExpr="text"
                          valueExpr="id"
                          showClearButton={true}
                          items={this.state.InspectionFormItems}
                          selectedItem={this.state.InspectionForm}
                          label="Inspection Form"
                          onSelectionChanged={(e: any) =>
                            this.handleDropdownChange(e, "InspectionForm")
                          }
                        />
                      </Grid>
                      {this.state.contractor && (
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            dataSource={this.state.councilContactItems}
                            value={this.state.councilContactUserFullName}
                            label="Council Contact Name"
                            disabled={
                              this.state.status &&
                              (this.state.status.id ===
                                global.contractorStartedStatus ||
                                this.state.status.id ===
                                  global.returnStatusid ||
                                (this.state.previousstatus &&
                                  this.state.previousstatus.id ===
                                    global.withContractorStatusId))
                                ? true
                                : false
                            }
                            onValueChanged={(e: any) =>
                              this.handleautoCompletechange(
                                e,
                                "councilContactUserFullName"
                              )
                            }
                          />
                        </Grid>
                      )}
                      {this.state.contractor && (
                        <Grid item xs={12} sm={6}>
                          <TextBoxInput
                            stylingMode="underlined"
                            label="Council Contact Email"
                            value={this.state.councilContactEmail}
                            disabled={
                              this.state.status &&
                              (this.state.status.id ===
                                global.contractorStartedStatus ||
                                this.state.status.id ===
                                  global.returnStatusid ||
                                (this.state.previousstatus &&
                                  this.state.previousstatus.id ===
                                    global.withContractorStatusId))
                                ? true
                                : false
                            }
                            onValueChanged={(e: any) =>
                              this.handleValueChange(e, "councilContactEmail")
                            }
                          />
                        </Grid>
                      )}
                      {this.state.contractor && (
                        <Grid item xs={12} sm={6}>
                          <TextBoxInput
                            stylingMode="underlined"
                            label="Council Contact Number"
                            value={this.state.councilContactPhone}
                            disabled={
                              this.state.status &&
                              (this.state.status.id ===
                                global.contractorStartedStatus ||
                                this.state.status.id ===
                                  global.returnStatusid ||
                                (this.state.previousstatus &&
                                  this.state.previousstatus.id ===
                                    global.withContractorStatusId))
                                ? true
                                : false
                            }
                            onValueChanged={(e: any) =>
                              this.handleValueChange(e, "councilContactPhone")
                            }
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={3}>
                            <CheckBoxInput
                              value={this.state.befpicreq}
                              onValueChanged={(e: any) =>
                                this.handleValueChange(e, "befpicreq")
                              }
                              text={"Before Photo Required"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <CheckBoxInput
                              value={this.state.aftpicreq}
                              onValueChanged={(e: any) =>
                                this.handleValueChange(e, "aftpicreq")
                              }
                              text={"After Photo Required"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <CheckBoxInput
                              value={this.state.riskassessment}
                              onValueChanged={(e: any) =>
                                this.handleValueChange(e, "riskassessment")
                              }
                              text={"Site Risk Assessment"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <CheckBoxInput
                              value={this.state.isWorkorderOvertime}
                              onValueChanged={(e: any) =>
                                this.handleValueChange(e, "isWorkorderOvertime")
                              }
                              text={"Overtime Work Order"}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Spinner
                      size="large"
                      label="Loading all componenent. Please wait..."
                    />
                  </div>
                )}
              </div>
            </ScrollView>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.props.onClose()}
              variant="contained"
              color="primary"
              className={""}
            >
              Cancel
            </Button>
            <Button
              onClick={() => this.update()}
              variant="outlined"
              color="primary"
              className={"button"}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

import * as React from 'react';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import {
  NavLink,
} from "react-router-dom";
import utility from '../../classes/utility';
import { Config } from '../../classes/config';
import AddAttachment from '../AddAttachment/AddAttachment';
import Grid from '@material-ui/core/Grid';
import { Popup } from 'devextreme-react/popup';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AlertDialog from '../common/alertDialog';
import DisplayDetailsModal from '../common/DisplayDetailModal/DisplayDetailModal';
import Spinner from '../common/reusableComponents/Spinner';
import AssignDatatable from '../common/DataTable/AssignDatatable';
import LoadIndicator from 'devextreme-react/load-indicator';
import AddNotes from '../AddNotes/AddNotes';
import EditWorkOrder from '../EditWorkOrder/EditWO';
import MyMapComponent from '../common/MapComponent/GoogleMapComponent';
import Paper from '@material-ui/core/Paper';
import AssignPlant from './AssignVehicle';
import global from "../../classes/global";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import NoteAddRounded from '@material-ui/icons/NoteAddRounded';
import BuildIcon from '@material-ui/icons/Build';
import PlaceOutlined from '@material-ui/icons/PlaceOutlined';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import { ScrollView } from 'devextreme-react';
let _WOhistoryItems: any[] = [];
let _DocPhotosItems: any[] = [];
let _worktimeItems: any[] = [];
let _plantusageItems: any[] = [];
let _relatedWOItems: any[] = [];
let _materialWOItems: any[] = [];
let _meterReadingItems: any[] = [];
export default class SearchWODetail extends React.Component<any, any> {
  public allocationReferences: any = '';
  public worktypeItems: any = [];
  public StatusItems: any = [];
  public WorkOrderID: any;
  public WorkOrderNumber: any;
  public source: any;
  public SRID: any;
  public currentUser: any;
  public _WOhistorycolumns: any;
  public _materialcolumns: any;
  public _Docphotoscolumns: any;
  public _worktimescolumns: any;
  public _plantusagecolumns: any;
  public _relatedWOcolumns: any;
  public _AssetWOcolumns: any;
  public _MaterialUsageWOcolumns: any;
  public _DefectsWOcolumns: any;
  public _DefectsResolvedWOcolumns: any;
  public _purchaseRequisitioncolumns: any;
  public _meterReadingColumns: any;
  public crewItems: any = [];
  public priorityItems: any = [];
  public interval: any;
  constructor(props: any) {
    super(props);
    if (!this.props.Department) {
      window.open('#/AccessRequest', '_self');
    };
    this.worktypeItems = [];
    this.currentUser = this.props.currentUser;
    this.crewItems = [];
    this.StatusItems = [];
    this.priorityItems = [];
    _WOhistoryItems = [];
    _DocPhotosItems = [];
    _worktimeItems = [];
    _plantusageItems = [];
    _relatedWOItems = [];
    _materialWOItems = [];
    _meterReadingItems = [];
    this.viewDetials = this.viewDetials.bind(this);
    this.ConfirmDialog = this.ConfirmDialog.bind(this);
    this.OnclickRefresh = this.OnclickRefresh.bind(this);

    this._WOhistorycolumns = [
      {
        field: 'Status',
        title: 'Status',
        width: 260,
        type: 'string'
      },
      {
        field: 'CreatedInDB',
        title: 'Added Date',
        width: 160,
        type: 'datetime'
      },

      // {
      //   field: 'updated',
      //   title: 'Updated',
      //   width: 160,
      //   type: 'datetime',
      //   sortOrder: 'desc'
      // },
      {
        field: 'Comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
    ];
    this._Docphotoscolumns = [
      {
        field: 'id',
        title: 'ID',
        // width: 160,
        type: 'string'
      },
      {
        field: 'document',
        title: 'Document',
        // width: 160,
        type: 'string',
        link: 'file',
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 160,
        type: 'string'
      },
      {
        field: 'uploadedon',
        title: 'Uploaded On',
        // width: 160,
        type: 'datetime'
      },
      {
        field: 'uploadedby',
        title: 'Uploaded By',
        // width: 160,
        type: 'string'
      },
    ];
    this._worktimescolumns = [
      {
        field: 'employee',
        title: 'Employee',
        type: 'string',
        allowHiding: false,
        groupIndex: 0
      },
      {
        field: 'type',
        title: 'Type',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'starttime',
        title: 'Start Time',
        type: 'datetime',
        allowHiding: true,
        sortOrder: 'desc',
        cellRender: "cellRender"
      },
      {
        field: 'endtime',
        title: 'End Time',
        type: 'datetime',
        allowHiding: true,
        cellRender: "cellRender"
      },
      {
        field: 'hoursworked',
        title: 'Hours worked',
        type: 'string',
        allowHiding: true,
        cellRender: "cellRender"
      },
      {
        field: 'isWorkorderOvertime',
        title: 'Is Over Time',
        type: 'string',
        allowHiding: true
      },
      {
        field: '',
        title: '',
        type: 'string',
        allowHiding: true,
        link: 'timesheetLink',
      },
    ];
    this._plantusagecolumns = [
      {
        field: 'plantnumber',
        title: 'Plant and Fleet #',
        // width: 160,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'title',
        title: 'Title',
        // width: 160,
        type: 'string',
        allowHiding: true,
      },
      {
        field: 'Regonumber',
        title: 'Rego #',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'hoursWorked',
        title: 'Hours Worked',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },

      {
        field: 'createdOn',
        title: 'Created',
        width: 160,
        type: 'datetime',
        allowHiding: true
      },
      {
        field: 'createdBy',
        title: 'Created By',
        width: 160,
        type: 'string',
        allowHiding: true
      }
    ];
    this._relatedWOcolumns = [

      {
        field: 'id',
        title: 'Work Order #',
        // width: 160,
        type: 'string',
        allowHiding: false,
        link: 'workorderLink',
      },
      {
        field: 'title',
        title: 'Title',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'activitycode',
        title: 'Activity',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'department',
        title: 'Department',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'team',
        title: 'Team',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'responsibleperson',
        title: 'Crew',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'status',
        title: 'status',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'schstartdate',
        title: 'Scheduled Start Date',
        // width: 280,
        type: 'datetime',
        allowHiding: false,
      }
    ];
    this._AssetWOcolumns = [

      {
        field: 'erpExtAssetId',
        title: 'Asset #',
        // width: 160,
        type: 'string',
        link: 'assetLink',
        allowHiding: false
      },
      {
        field: 'AssetName',
        title: 'Asset Name',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'Location',
        title: 'Location',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'ServiceStatus',
        title: 'ServiceStatus',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
    ];
    this._materialcolumns = [
      {
        field: 'id',
        title: 'ID',
        // width: 160,
        type: 'string',
        allowHiding: false
      },
      {
        field: 'materialName',
        title: 'Material Name',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'quantity',
        title: 'Quantity',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'measuringUnit',
        title: 'Measuring Unit',
        // width: 280,
        type: 'string',
        allowHiding: false,
      },
      {
        field: 'comments',
        title: 'Comments',
        // width: 280,
        type: 'string',
        allowHiding: false,
      }
    ];
    this._meterReadingColumns = [
      {
        field: 'name',
        title: 'Name',
        type: 'string',
        allowHiding: false,
        groupIndex: 0
      },
      {
        field: 'value',
        title: 'Value',
        type: 'string',
        allowHiding: true
      },
      {
        field: 'date',
        title: 'Date',
        type: 'datetime',
        allowHiding: true,
        sortOrder: 'desc'
      },
      {
        field: 'unit',
        title: 'Unit',
        type: 'string',
        allowHiding: true
      }
    ];
    this.state = {
      WOhistoryItems: [],
      AssetWOItems: [],
      WOhistorycolumns: this._WOhistorycolumns,
      materialWOItems: [],
      materialcolumns: this._materialcolumns,
      WOhistoryCount: 0,
      DocPhotosItems: [],
      DocPhotoscolumns: this._Docphotoscolumns,
      DocPhotosCount: 0,
      plantusageItems: [],
      plantusagecolumns: this._plantusagecolumns,
      plantusageCount: 0,
      relatedWOItems: [],
      relatedWOcolumns: this._relatedWOcolumns,
      relatedWOCount: 0,
      worktimeItems: [],
      worktimecolumns: this._worktimescolumns,
      worktimeCount: 0,
      materialUsageItems: [],
      materialUsagecolumns: this._MaterialUsageWOcolumns,
      MatUsageCount: 0,
      AssetWOcolumns: this._AssetWOcolumns,
      AssetWOCount: 0,
      DefectsWOItems: [],
      DefectsWOcolumns: this._DefectsWOcolumns,
      DefectsWOCount: 0,
      DefectsResolvedWOItems: [],
      DefectsResolvedWOcolumns: this._DefectsResolvedWOcolumns,
      DefectsResolvedWOCount: 0,
      purchaseRequisitionItems: [],
      purchaseRequisitioncolumns: this._purchaseRequisitioncolumns,
      purchaseRequisitionCount: 0,
      meterReadingItems: [],
      meterReadingColumns: this._meterReadingColumns,
      selected: 0,
      lng: 0,
      lat: 0,
      selectedTopTab: 0,
      loadMap: false
    };
  }

  public async componentDidMount() {
    await this.fetchData();
  }

  public async componentDidUpdate(prevProps: Readonly<any>) {
    // COA-186 Check if the location props have changed
    if (this.props.location !== prevProps.location) {
      await this.fetchData();
    }
  }

  private async fetchData() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let workid = params.get('WOID');
    let SR = params.get('SR');

    if (workid !== this.WorkOrderID) {
      this.WorkOrderID = workid;
      this.SRID = SR;
      this.allocationReferences = '';

      await Promise.all([
        this.loadWorkOrderDetails(),
        this.getRelatedWO(),
        this.getWorkOrderHistory(),
        this.getWODocument(),
        this.getTimesheetdata(),
        this.getWOMaterialdata(),
        this.getPlantUsageData(),
        this.getMeterReadingData()
      ]);
    }
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
  }

  public handleOpen(item: any) {
    this.setState({ ImageURL: item.fileURL, isOpen: true });
  }
  public openImageModal() {
    return (
      <Popup
        width={'45vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={this.state.isOpen}
        onVisibleChange={() => this.setState({ isOpen: false })}
      >
        <ScrollView width='100%' height='100%' >
          <div className='popContentDiv'>
            <img src={this.state.ImageURL} style={{ width: '100%', height: '100' }} />
          </div>
        </ScrollView>
      </Popup>
    )
 
  }

  public async OnclickRefresh() {
    await Promise.all([
      this._getStatusDataAsync(),
      this.loadWorkOrderDetails(),
      this.getRelatedWO(),
      this.getWorkOrderHistory(),
      this.getWODocument(),
      this.getTimesheetdata(),
      this.getWOMaterialdata(),
      this.getPlantUsageData(),
      this.getMeterReadingData()
    ]);
  }

  private async getWOMaterialdata() {
    let dataToPassToService: any = {};
    dataToPassToService.workorderId = this.WorkOrderID;
    try {
      const data: any = await utility.genericPostAPIcall(Config.workorderMateriallistname, dataToPassToService);
      _materialWOItems = [];
      if (data.length !== null || data.length !== 0) {
        data.forEach((item: any) => {
          _materialWOItems.push({
            Workordernumber: item.workorderId,
            materialName: item.materialName,
            title: item.workOrderTitle,
            id: item.id,
            measuringUnit: item.measuringUnit,
            quantity: item.quantity,
            comments: item.comments
          });
        });
        _materialWOItems = utility._sortItems(_materialWOItems, 'createdOn', true);
        this.setState({
          materialWOItems: _materialWOItems,
          callAfterInit: true,
          loadingmaterialWO: false
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }
  private async _getStatusDataAsync(statusId?: any) {
    try {
      const data: any = await utility.genericGetAPICall(`${Config.statuslist}?showAll=true`);
      const filteredData = data.filter((r: any) => r.isActive);
      const mappedData = filteredData.map((r: any) => {
        if (
          r.id === global.returnStatusid ||
          r.id === global.contractorStartedStatus ||
          r.id === global.InProgressStatus ||
          (statusId === global.withContractorStatusId &&
            (r.id === global.InProgressStatus || r.id === global.OnHoldStatusId))
        ) {
          return { id: r.id, text: r.title, disabled: true };
        } else {
          return { id: r.id, text: r.title };
        }
      });
      mappedData.sort((a: any, b: any) => a.text.localeCompare(b.text));
      this.StatusItems = mappedData;
      let selectedstatusItem = this.StatusItems.filter((f: any) => f.id === statusId);
      this.setState({ StatusItems: this.StatusItems, statusId: selectedstatusItem && selectedstatusItem.length > 0 ? selectedstatusItem[0].id : '', statustext: selectedstatusItem && selectedstatusItem.length > 0 ? selectedstatusItem[0].text : '' });
    } catch (err) {
      console.error(err);
    }
  }
  private _getworkTypeDataAsync(workorderTypeId: any) {
    try {
      const data: any = utility.genericGetDataByIdAPICall(Config.WTlistname, workorderTypeId);
      if (data) {
        this.setState({ worktypeName: data.title });
      }
    } catch (err) {
      console.log(err);
    }
  }
  public viewDefectDetials(item: any, mode: any) {
    // console.log(item);
    this.setState({
      defectDetails: item,
      showDefectDetailsModal: true,
      mode: mode
    });
  }

  public viewDefectResolvedDetials(item: any) {
    // console.log(item);
    this.setState({
      defectResolvedDetails: item,
      showDefectResolvedDetailsModal: true
    });
  }
  private _getPrioritiesData(priority: any) {
    try {
      const data: any = utility.genericGetAPICall(`${Config.PriorityListName}?showAll=true`);
      if (data && data.length === 0) {
        const priorityItems = data
          .filter((r: any) => r.type === 'WorkOrder')
          .map((r: any) => ({ id: r.id, text: r.title }));
        const priorityItem = priorityItems.find((item: any) => item.id === priority);
        const stateUpdate = {
          priorityItems: priorityItems,
          priorityId: priorityItem ? priorityItem.id : null,
          priority: priorityItem ? priorityItem.text : null,
        };
        this.setState(stateUpdate);
      }
    } catch (err) {
      console.log(err);
    }
  }
  public loadAssetDetails(AsseticIDs: any) {
    this.setState({ loadingAssetDetail: true });

    let dataToPassToService: any = {};
    dataToPassToService.assetIds = AsseticIDs;
    utility.genericPostAPIcall(Config.SearchAssetDetails, dataToPassToService)
      .then((data: any) => {
        let assetDetailsItems: any[] = [];
        if (data.length > 0) {
          data.forEach((item: any) => {
            assetDetailsItems.push({
              Register: item.registerTitle,
              AssetNumber: item.id,
              id: item.id,
              locationId: item.locationId,
              AssetClass: item.classTitle,
              AssetID: item.id,
              AssetName: item.title,
              erpAssetId: item.erpAssetId,
              erpExtAssetId: item.erpExtAssetId !== null ? item.erpExtAssetId : item.erpAssetId ? item.erpAssetId : '',
              ServiceStatus: item.assetStatus,
              Description: item.description,
              Location: item.locationDescription,
              Category: item.assetCategoryTitle,
              erpExtSystemName: item.erpExtSystemName,
              maintenanceType: item.maintenanceAssetType,
              accntSystemId: item.accntSystemId,
              workGroupIds: item.workGroupIds,
              activityId: item.activityId,
              taskId: item.taskId,
              geometry: item.geom,
              AssetType: item.typeTitle
            });
          });

          this.setState({
            AssetWOItems: assetDetailsItems,
            loadingAssetDetail: false
          });
        } else {
          this.setState({
            assetdetails: [],
            loadingAssetDetail: false
          });
        }


      })
      .catch((err: any) => {
        console.log(err);
        this.setState({ assetdetails: [], loadingAssetDetail: false });
      });

  }
  public getFormData(formIds: any) {

    if (formIds && formIds.length > 0) {
      let dataToPassToService: any = {};
      dataToPassToService.formIds = formIds;
      utility.genericPostAPIcall(Config.searchForms, dataToPassToService).then((formdata: any) => {
        let formTitle: String = '';
        if (formdata && formdata.length > 0) {
          {
            formdata.map((item: any, i: any) => {
              formTitle += item.title + ',';
            })
          }
          formTitle = formTitle.substring(0, formTitle.length - 1);
        }
        this.setState({ formTitle: formTitle });
      });
    }
  }
  public async loadWorkOrderDetails() {
    let statusItems: any;
    this.setState({ loading: true, callAfterInit: true });
    let dataToPassToService: any = {};
    dataToPassToService.workorderId = this.WorkOrderID;
    utility.genericPostAPIcall(Config.WOSearchAPI, dataToPassToService).then((items: any) => {
      let data = items[0];
      if ((data.assessmentFormIds && data.assessmentFormIds.length > 0) ? data.assessmentFormIds : data.formIds) {
        this.getFormData((data.assessmentFormIds && data.assessmentFormIds.length > 0) ? data.assessmentFormIds : data.formIds);
      }
      if (data.assetIds && Array.isArray(data.assetIds) && data.assetIds.length > 0) { this.loadAssetDetails(data.assetIds) };
      if (data.workorderTypeId && (data.workorderTypeId !== null || data.workorderTypeId !== "")) { this._getworkTypeDataAsync(data.workorderTypeId) };
      this.getcrew(data);
      this._getStatusDataAsync(data.workorderStatusId)
      this._getPrioritiesData(data.priorityId);
      let item = data;
      var JSONPlantsData;
      const _plantWOItems: any = [];
      if (item.plantOperators !== null) {
        JSONPlantsData = JSON.parse(item.plantOperators);
        if (!Array.isArray(JSONPlantsData)) {

          JSONPlantsData = [JSONPlantsData];
        }
        JSONPlantsData.forEach(plantdata => {
          _plantWOItems.push({
            operatorFullName: plantdata.operatorFullName ? plantdata.operatorFullName : '',
            operatorId: plantdata.operatorId ? plantdata.operatorId : '',
            plantResourceCode: plantdata.plantResourceCode ? plantdata.plantResourceCode : '',
            plantTitle: plantdata.plantTitle ? plantdata.plantTitle : ''
          });
        });
      }

      this.setState({
        workorderdetails: data,
        callAfterInit: true,
        plantWOItems: _plantWOItems,
        WorkReqlink: "/WRDetail?ReqId=" + data.workRequestId,
        loading: false,
      });
    });

  }

  public async getPlantUsageData() {
    this.setState({ plantUsageLoading: true });
    let dataToPassToService: any = {};
    dataToPassToService.workorderId = this.WorkOrderID;
    try {
      const woplant: any = await utility.genericPostAPIcall(Config.WOequipmentSearchAPI, dataToPassToService);
      _plantusageItems = [];
      if (woplant.length !== null || woplant.length !== 0) {
        woplant.forEach((item: any) => {
          let starttime: any = new Date(item.startTime);
          let endTime: any = new Date(item.endTime);
          let delta: any = Math.abs(endTime - starttime) / 1000;
          let days = Math.floor(delta / 86400);
          delta -= days * 86400;

          // calculate (and subtract) whole hours
          let hours = Math.floor(delta / 3600) % 24;
          delta -= hours * 3600;

          // calculate (and subtract) whole minutes
          let minutes = Math.floor(delta / 60) % 60;
          delta -= minutes * 60;
          _plantusageItems.push({
            Workordernumber: item.workorderId,
            techoneWorkordernumber: item.erpWorkorderNumber,
            title: item.title,
            planttype: item.title,
            id: item.id,
            plantnumber: item.resourceCode,
            Category: item.category,
            Regonumber: item.regoNumber,
            Plantid: item.equipmentId,
            Comments: item.comments,
            starttime: item.startTime,
            endtime: item.endTime,
            viewTimesheet: '',
            hoursWorked: days + ' days ' + hours + ' hours ' + minutes + ' minutes',
            Editedstarttime: item.startTime,
            Editedendtime: item.endTime,
            woid: item.Workorderid,
            updatedBy: item.updatedBy,
            createdBy: item.createdBy,
            createdOn: item.created,
          });
        });
        _plantusageItems = utility._sortItems(_plantusageItems, 'createdOn', true);
        this.setState({
          plantusageCount: woplant.length,
          plantusageItems: _plantusageItems,
          callAfterInit: true,
          plantUsageLoading: false
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }
  public async getTimesheetdata() {
    this.setState({ timesheetLoading: true });
    let dataToPassToService: any = {};
    dataToPassToService.workorderId = this.WorkOrderID;
    try {
      const wotimesheet: any = await utility.genericPostAPIcall(Config.WOTimeSheetAPI, dataToPassToService);
      _worktimeItems = [];
      //console.log("wotimesheet", wotimesheet);
      if (wotimesheet.length !== null || wotimesheet.length !== 0) {
        wotimesheet.forEach((item: any) => {
          const userTimezone = moment.tz.guess();
          let startTime = null; let endTime = null; let Editedstarttime = null; let Editedendtime = null;

          if (item.startTime) {
            startTime = moment.utc(item.startTime).tz(userTimezone);
          }
          if (item.endTime) {
            endTime = moment.utc(item.endTime).tz(userTimezone);
          }
          if (item.editedStartTime) {
            Editedstarttime = moment.utc(item.editedStartTime).tz(userTimezone);
          }
          if (item.editedFinishTime) {
            Editedendtime = moment.utc(item.editedFinishTime).tz(userTimezone);
          }

          const type = item.type;
          const result = type.replace(/([A-Z])/g, " $1");
          const formattedtype = result.charAt(0).toUpperCase() + result.slice(1);
          _worktimeItems.push({
            //id: item.Id,
            Workordernumber: item.workorderId,
            asseticWONumber: item.erpWorkorderNumber,
            type: formattedtype,
            employee: item.employeeName,
            starttime: startTime ? startTime.format('DD/MM/YYYY hh:mm A') : '',
            endtime: endTime ? endTime.format('DD/MM/YYYY hh:mm A') : '',
            Editedendtime: Editedendtime ? Editedendtime.format('DD/MM/YYYY hh:mm A') : '',
            Editedhoursworked: item.editedHoursWorked ? item.editedHoursWorked : '',
            viewTimesheet: '',
            plants: item.plants,
            isWorkorderOvertime: item.isWorkorderOvertime ? "Yes" : "No",
            hoursworked: item.hoursWorked,
            updatedBy: item.updatedBy,
            updatedOn: item.updated,
            createdBy: item.createdBy,
            createdOn: item.created,

          });
        });
        _worktimeItems = utility._sortItems(_worktimeItems, 'createdOn', true);
        this.setState({
          worktimeCount: wotimesheet.length,
          worktimeItems: _worktimeItems,
          callAfterInit: true,
          timesheetLoading: false
        });
      }

    }
    catch (err) {
      console.log(err);
    }

  }
  public async getWorkOrderHistory() {
    this.setState({ historyLoading: true });
    let dataToPassToServiceForWOHistory: any = {};
    dataToPassToServiceForWOHistory.workorderId = +this.WorkOrderID;
    try {
      const wohistory: any = await utility.genericPostAPIcall(Config.WOHistorySearchAPI, dataToPassToServiceForWOHistory);
      _WOhistoryItems = [];
      if (wohistory.length !== null || wohistory.length !== 0) {
        wohistory.forEach((item: any) => {
          let usercomments = item.comments ? item.comments.replace(/<br ?\/?>/g, "\n") : '';
          _WOhistoryItems.push({
            id: item.id,
            Status: item.statusTitle ? item.statusTitle : '',
            updated: item.updated,
            CreatedInDB: item.created,
            Comments: usercomments,
          });


        });
        _WOhistoryItems = utility._sortItems(_WOhistoryItems, 'CreatedInDB', true);
        this.setState({
          WOhistoryCount: wohistory.length,
          WOhistoryItems: _WOhistoryItems,
          callAfterInit: true,
          historyLoading: false
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }
  public async getMeterReadingData() {
    this.setState({ meterReadingLoading: true });
    try {
      const meterReadingData: any = await utility.genericGetDataByIdAPICall(Config.AssetMeterReadingListByWOID, this.WorkOrderID);
      _meterReadingItems = [];
      if (meterReadingData && meterReadingData.meterReadingValues && meterReadingData.meterReadingValues.length > 0) {
        meterReadingData.meterReadingValues.forEach((reading: any) => {
          _meterReadingItems.push({
            name: reading.meterReadingName,
            value: reading.meterReadingValue,
            date: new Date(reading.meterReadingDate),
            unit: reading.meterReadingUnit
          });
        });
      }
      this.setState({
        meterReadingItems: _meterReadingItems,
        meterReadingColumns: this._meterReadingColumns,
        meterReadingLoading: false,
        callAfterInit: true
      });
    }
    catch (err) {
      console.log(err);
      this.setState({ meterReadingLoading: false })
    }
  }
  public viewDetials(item: any) {

    this.setState({
      details: item,
      showModal: true
    });
  }
  public async getRelatedWO() {
    this.setState({ loadingrelatedWO: true });
    let dataToPassToServiceForRWO: any = {};
    try {
      const relatedWO: any = await utility.genericPostAPIcall(Config.SearchRelatedWOAPI + "?workorderId=" + this.WorkOrderID, dataToPassToServiceForRWO);

      _relatedWOItems = [];
      if (relatedWO.length !== null || relatedWO.length !== 0) {
        relatedWO.forEach((item: any) => {
          if (item.id !== this.WorkOrderID) {
            _relatedWOItems.push({
              id: item.id,
              woid: item.id,
              Workordernumber: item.id,
              erpWorkorderNumber: item.erpWorkorderNumber,
              title: item.title ? item.title : '',
              createdOn: item.created,
              responsibleperson: item.crew ? item.crew : '',
              activitycode: item.worktypeName ? item.worktypeName : '',
              WOPriority: item.priority ? item.priority : '',
              team: item.team ? item.team : '',
              department: item.department ? item.department : '',
              status: item.status ? item.status : '',
              schstartdate: item.dateScheduledStart,
              workrequest: item.workRequestId ? item.workRequestId : '',
              relatedWO: "Yes"
            });
          }
        });
        _relatedWOItems = utility._sortItems(_relatedWOItems, 'createdOn', true);

        this.setState({
          relatedWOCount: relatedWO.length,
          relatedWOItems: _relatedWOItems,
          callAfterInit: true,
          loadingrelatedWO: false
        });
      }
    } catch (err) {
      console.log(err);
    }

  }
  public async getWODocument() {
    this.setState({ documentloading: true })
    try {
      const wodoc: any = await utility.genericGetDataByIdAPICall(Config.workorderDocumentListGet, this.WorkOrderID);
      _DocPhotosItems = [];
      if (wodoc.length !== null || wodoc.length !== 0) {
        wodoc.forEach((item: any) => {
          const filext = item.name.split('.');
            _DocPhotosItems.push({
              document: item.name ? item.name : '',
              uploadedon: item.created,
              id: item.id ? item.id : ' ',
              uploadedby: item.updatedBy ? item.updatedBy : null,
              fileURL: item.url ? item.url : '',
              title: item.title ? item.title : '',
              comments: item.comments ? item.comments : '',
              fileExt: filext && filext.length > 0 ? filext[1] : ''
            });
          
        });
        _DocPhotosItems = utility._sortItems(_DocPhotosItems, 'uploadedon', true);
        this.setState({
          DocPhotosCount: wodoc.length,
          DocPhotosItems: _DocPhotosItems,
          callAfterInit: true,
          documentloading: false
        });
      }
    }
    catch (err) {
      console.log(err);
    }
  }
  public ConfirmDialog(item: any) {
    this.setState({
      ConfirmalertMessage: 'Are you sure you want to edit Asset for the work order?',
      showConfirmAlert: true,
      Asset: item
    });
  }
  private alert() {
    return (
      <AlertDialog
        showDialog={this.state.showAlert}
        message={this.state.alertMessage}
        title={Config.productTitle}
        onClose={() => this.setState({ alertMessage: '', showAlert: false })}
        hideAlertButton={this.state.hideAlertButton}
      />
    );
  }
  public viewdocument(a: any) {
    let item = a;
    let dataToPassToService: any = {};
    if (!item.document) {
      this.setState({
        alertMessage: 'Unable to view this document. \n File URL or File name does exist',
        showAlert: true
      });
      return;
    }
    dataToPassToService.fileName = item.document;
    dataToPassToService.fileUrl = item.fileURL;
    if ((item.fileURL).includes(global.isAzurefile)) {
      utility.genericPostAPIcallForDowloadDoct(Config.dowloadDocumentList, dataToPassToService).then((doc: any) => {
        window.open(doc, "_blank");
      });
    }
    else {
      let fileurl = item.fileURL.toLowerCase();

      var pattern = /.*href="(.*)".*/;
      var href = fileurl.replace(pattern, '$1');

      let docurl = "<a href=" + href + "></a>"
      window.open(docurl, "_blank");
    }

    dataToPassToService.fileName = item.document;
    dataToPassToService.fileUrl = item.fileURL;


  }
  public viewTimeDetails(item: any) {
    this.setState({
      timeEditdetails: item,
      showTimesheetModal: true
    });
  }
  public renderTimesheetDetailsModal() {
    return (
      <DisplayDetailsModal
        columns={this._worktimescolumns}
        details={this.state.timeEditdetails}
        showModal={this.state.showTimesheetModal}
        onClick={() => this.setState({ showTimesheetModal: false })}
      />
    );
  }
  private _onGenerateLink = (): void => {
    this.setState({ linkGenerating: true });
    let dataToPassToService: any = {};
    dataToPassToService.workOrderId = this.state.workorderdetails.id;
    dataToPassToService.portalUrl = global.domainName;
    dataToPassToService.updatedBy = this.currentUser.Email;
    dataToPassToService.contractorId = this.state.workorderdetails.contractorId;
    utility.genericPostAPIcall(Config.PortalLink, dataToPassToService).then((data: any) => {
      console.log(data);
      this.setState({
        alertMessage: `Email with link has been sent to the contractor ${this.state.workorderdetails.contractorBusinessName}.`,
        showAlert: true,
        hideAlertButton: false,
        linkGenerating: false,
        loading: false
      });

    });
  }

  public viewTimeForPlantDetails(item: any) {
    this.setState({
      timeEditForPlantdetails: item,
      showPlantEditTimeModal: true
    });
  }
  public renderPlantEditTimeModall() {
    return (
      <DisplayDetailsModal
        columns={this._plantusagecolumns}
        details={this.state.timeEditForPlantdetails}
        showModal={this.state.showPlantEditTimeModal}
        onClick={() => this.setState({ showPlantEditTimeModal: false })}
      />
    );
  }
  public openWOModal() {
    this.setState({
      showCommsModal: true,
      callAfterInit: true
    });
  }
  private _closeNotesModal = (): void => {
    this.setState({ showNotesPanel: false, callAfterInit: false });
  }
  private stopCommsAfterInit = (): void => {
    this.setState({ callAfterInit: false });
  }
  public workorderUpdated() {
    this.setState({
      showCommsModal: false, showNotesPanel: false, openPlantAssignModal: false
    });
    this.OnclickRefresh();
  }
  public getcrew(data: any) {
    utility.genericGetAPICall(`${Config.crewswithdepartment}?showAll=true`).then((crewdata: any) => {
      crewdata.forEach((element: any) => {
        if (data.crewId === element.id) {
          this.setState({
            Crewname: element.title,
            DepartmentName: element.departmentName,
          });
        }

        this.crewItems.push({ id: element.id, text: element.title, teamId: element.teamId, departmentId: element.departmentId });

      });
      const sortedItems = utility._sortItems(this.crewItems, 'text', false);
      this.setState({ crewItems: sortedItems });
    }
      , (err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }
  public renderEditWOPanel() {
    // var aadHttpClient = this.props.aadHttpClient;
    return (
      <EditWorkOrder
        workorder={this.state.workorderdetails}
        showModal={this.state.showCommsModal}
        selectedcrew={this.state.Crewname}
        callAfterInit={this.state.callAfterInit}
        onClick={() => this.workorderUpdated()}
        onClose={() => this.workorderUpdated()}
        currentUser={this.props.currentUser}
        context={this.props.context}
        aadHttpClient={this.props.aadHttpClient}
        priorityId={this.state.priorityId}
        statustext={this.state.statustext}
        statusId={this.state.statusId}
        crewItems={this.state.crewItems}
        StatusItems={this.state.StatusItems}
        AssetDetails={this.state.AssetWOItems}
        priorityItems={this.state.priorityItems}
      />
    );
  }
  public renderPlantAssignModal() {
    // var aadHttpClient = this.props.aadHttpClient;
    return (
      <AssignPlant
        workorder={this.state.workorderdetails}
        showModal={this.state.openPlantAssignModal}
        onClick={() => this.workorderUpdated()}
        onClose={() => this._closePlantAssignModal()}
        context={this.props.context}
        currentUser={this.currentUser}
        aadHttpClient={this.props.aadHttpClient}
        WorkOrderID={this.WorkOrderID}
      //  aadHttpClient={aadHttpClient}
      />
    );
  }
  private _closePlantAssignModal = (): void => {
    this.setState({ openPlantAssignModal: false });
  }

  private _showModalofAttachment = (): void => {
    this.setState({
      showModalofAttachment: true
    });
  }
  private _closeModalofAttachment = (item: any): void => {
    //console.log(item);
    this.setState({
      showModalofAttachment: false
    });
    if (item === true) {
      this.setState({
        alertMessage: 'Attachment(s) uploaded successfully.',
        showAlert: true,
      });
    }
  }
  public renderNotes() {
    return (
      <AddNotes
        workorder={this.state.workorderdetails}
        showModal={this.state.showNotesPanel}
        callAfterInit={this.state.callAfterInit}
        onClick={() => this.workorderUpdated()}
        onClose={() => this._closeNotesModal()}
        currentUser={this.props.currentUser}
        context={this.props.context}
        aadHttpClient={this.props.aadHttpClient}
      />
    );
  }
  public renderAttachmentModal() {
    return (
      <AddAttachment
        showModal={this.state.showModalofAttachment}
        onClose={(item: any) => this._closeModalofAttachment(item)}
        workorderID={this.WorkOrderID}
        context={this.props.context}
        details={this.state.workorderdetails}
        aadHttpClient={this.props.aadHttpClient}
        currentUser={this.currentUser}
        message={() => this.message()}
        updateDoc={() => setTimeout(this.getWODocument.bind(this), 2000)}
      />
    );
  }
  public rendermapModal() {
    return (
      <Popup
        width={'80vw'}
        maxHeight={'85vh'}
        minHeight={200}
        height={'auto'}
        showTitle={true}
        showCloseButton={true}
        dragEnabled={true}
        hideOnOutsideClick={false}
        visible={this.state.showMapPanel}
        closeOnOutsideClick={true}
        onVisibleChange={() => this.setState({ showMapPanel: false })}
      >
        <MyMapComponent
          singleMarkers={{ lng: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.x : 0, lat: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.y : 0 }}
          center={{ lng: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.x : 0, lat: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.y : 0 }}
          useStyle={this.state.useStyle}
          address={this.state.workorderdetails.workorderLocation}
          iconColor={'33cc33'}
          zoom={18}
          height="35vh"
          showMarker={true}
        />

      </Popup>
    );
  }
  public message() {
    this.setState({
      alertMessage: 'Work order document has been added',
      showAlert: true
    });
  }
  public handleSelect = (event: any, newValue: any) => {
    this.setState({ selected: newValue });
  }
  public renderDetialsModal() {
    return (
      <DisplayDetailsModal
        columns={this._DefectsWOcolumns}
        details={this.state.defectDetails}
        mode={this.state.mode}
        showModal={this.state.showDefectDetailsModal}
        onClick={() => this.setState({ showDefectDetailsModal: false, defectDetails: null })}
      />
    );
  }
  // for purchase requistion detail lineitems

  public viewRequistionDetail(item: any) {
    this.setState({
      showModalofPurchaseReq: true,
      purchaseReqDetails: item
    });
  }
  public renderPurchaseRequistionModal() {
    return (
      <DisplayDetailsModal
        columns={this._purchaseRequisitioncolumns}
        details={this.state.purchaseReqDetails}
        showModal={this.state.showModalofPurchaseReq}
        onClick={() => this.setState({ showModalofPurchaseReq: false, purchaseReqDetails: null })}
      />
    );
  }

  public loaderTab(title: any, items: any, counts: any, isLoading: any) {
    if (isLoading) {
      return <span className='tabHeading'>{title} <LoadIndicator id="small-indicator" height={15} width={15} /></span>
    } else {
      return <span className='tabHeading'>{title} ({counts})</span>
    }
  }
  public handleSelectTopTab = (event: any, newValue: any) => {
    this.setState({ selectedTopTab: newValue });
  }
  public render(): React.ReactElement<any> {
    const {
      worktimeItems, worktimecolumns,
      WOhistoryItems, WOhistorycolumns,
      DocPhotoscolumns, DocPhotosItems,
      plantusageItems, plantusagecolumns,
      relatedWOItems, relatedWOcolumns, workorderdetails, AssetWOItems, AssetWOcolumns,
      materialWOItems, materialcolumns, meterReadingItems, meterReadingColumns
    } = this.state;
    return (
      <div >
        {this.state.showDefectDetailsModal && this.renderDetialsModal()}
        {this.state.showTimesheetModal && this.renderTimesheetDetailsModal()}
        {this.state.showPlantEditTimeModal && this.renderPlantEditTimeModall()}
        {this.state.showModalofPurchaseReq && this.renderPurchaseRequistionModal()}
        {this.state.showAlert && this.alert()}
        {(this.state.workorderdetails) && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={6}>
              <div className={"SearchDiv headingDiv"}>
                <span className={"heading"}>
                  Work Order Details
                  {this.state.workorderdetails && (
                    <>
                      <span>
                        {' '}
                        - {this.state.workorderdetails.workorderNumber} ({this.state.workorderdetails.title})
                      </span>
                    </>
                  )}
                </span>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className='icon-buttons-list'>
                <IconButton aria-label="view" color="primary" onClick={() => this.OnclickRefresh()} >
                  <RefreshIcon titleAccess='Refresh the data' className={'iconcolor'} />
                </IconButton>
                <IconButton
                  onClick={() => this.openWOModal()}
                  disabled={this.state.workorderdetails.status === "Complete" ? true : false}
                >
                  <EditIcon titleAccess='Edit Work Order' className={'iconcolor'} />
                </IconButton>
                <IconButton
                  onClick={() => this.setState({ showNotesPanel: true })} disabled={this.state.workorderdetails.status === "Complete" ? true : false}
                >
                  <NoteAddRounded titleAccess='Add Notes' className={'iconcolor'} />
                </IconButton>
                {this.state.workorderdetails && this.state.workorderdetails.status === "With Contractor" &&
                  <IconButton
                    onClick={() => this._onGenerateLink()}
                  >
                    <EmailOutlined titleAccess='Send Contractors link' className={'iconcolor'} />{this.state.linkGenerating && <LoadIndicator id="small-indicator" height={15} width={15} />}
                  </IconButton>
                }
                <IconButton
                  onClick={() => this._showModalofAttachment()} disabled={this.state.workorderdetails.status === "Complete" ? true : false}                >
                  <AttachFileOutlinedIcon titleAccess='Attach Document' className={'iconcolor'} />
                </IconButton>
                <IconButton onClick={() => this.setState({ openPlantAssignModal: true })} disabled={this.state.workorderdetails.status === "Complete" ? true : false}>
                  <BuildIcon titleAccess='Assign/Modify Plant/Vehicle' className={'iconcolor'} />
                </IconButton>
                <IconButton onClick={() => this.setState({ showMapPanel: true })}>
                  <PlaceOutlined titleAccess='Show Map' className={'iconcolor'} />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        )}
        {
          (!this.state.workorderdetails ?
            <div>
              <Spinner size='large' label={'Loading details'} />
              <br />
            </div> : null
          )
        }

        {
          (this.state.workorderdetails &&
            <div className={"detailFieldDiv"}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Assetic Work Order #:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {this.state.workorderdetails.erpWorkorderNumber ? <a href={global.AsseticWOLink + this.state.workorderdetails.erpWorkorderNumber} className="WOlink" rel="noreferrer" target="_blank">{this.state.workorderdetails.erpWorkorderNumber}</a> : ''}

                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Assetic Req #:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {this.state.workorderdetails.erpWorkRequestNumber ? <a href={global.AsseticWRLink + this.state.workorderdetails.erpWorkRequestNumber} className="WOlink" rel="noreferrer" target="_blank">{this.state.workorderdetails.erpWorkRequestNumber}</a> : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Created By:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.createdByFullName}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Location:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.workorderLocation}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Description:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.description}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Priority:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.priority}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Created:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.created !== "" && this.state.workorderdetails.created !== null ? moment(this.state.workorderdetails.created).format("DD/MM/YY h:mm a") : ''}</Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Schedule Start:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {workorderdetails.dateScheduledStart ?
                        moment(workorderdetails.dateScheduledStart).format("DD/MM/YY h:mm a") : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Required Date:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {workorderdetails.requiredByDate ?
                        moment(workorderdetails.requiredByDate).format("DD/MM/YY h:mm a") : ''}
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Completion:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {workorderdetails.dateCompleted ?
                        moment(workorderdetails.dateCompleted).format("DD/MM/YY h:mm a") : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Mandatory Form:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {this.state.formTitle ? this.state.formTitle : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Inspection Form:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {this.state.workorderdetails?.inspectionFormTitle ? this.state.workorderdetails?.inspectionFormTitle : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Risk Assessment:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{(this.state.workorderdetails.riskAssessmentRequired === true) ? "Yes" : "No"}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Overtime Work Order:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{(this.state.workorderdetails.isWorkorderOvertime === true) ? "Yes" : "No"}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Before Photo:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.photoRequiredBefore ? 'Yes' : 'No'}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>After Photo:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{workorderdetails.photoRequiredAfter ? 'Yes' : 'No'}</Grid>
                  </Grid>
                  {this.state.plantWOItems && this.state.plantWOItems.length > 0 ?

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>Plant/Vehicle:</Grid>
                      <Grid item xs={12} sm={8} className={'semiBold'}>
                        {this.state.plantWOItems.map((p: any) => {
                          return (
                            <span>{`${p.plantResourceCode} - ${p.plantTitle} (${p.operatorFullName})`}<br /></span>
                          );
                        }
                        )}
                      </Grid>
                    </Grid>
                    : null}
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>worxOnline Work Order #:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.id}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Work Req #:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>
                      {this.state.workorderdetails.workRequestId ? <NavLink className="WOlink" to={this.state.WorkReqlink}>{this.state.workorderdetails.workRequestId}</NavLink> : ''}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Activity:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.worktypeName ? this.state.workorderdetails.worktypeName : ''}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Status:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.status}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Department:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.department}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Team:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.team}</Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Crew:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.crew}</Grid>
                  </Grid>

                  {this.state.workorderdetails && this.state.workorderdetails.contractorName &&
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Business Name:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.contractorBusinessName}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Business Contact Name:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.contractorName}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Business Contact Number:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.contractorPhone}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Council Contact Name:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.councilContactUserFullName}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Council Contact Email:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.councilContactEmail}</Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>Council Contact Number:</Grid>
                        <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.councilContactPhone}</Grid>
                      </Grid>

                    </div>
                  }
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>Assigned Resource:</Grid>
                    <Grid item xs={12} sm={8} className={'semiBold'}>{this.state.workorderdetails.leadingHand}</Grid>
                  </Grid>

                </Grid>
                <Grid item xs={12} sm={12} md={3}>

                  <Paper square className='mapwidth'>
                    <Tabs
                      value={this.state.selectedTopTab}
                      onChange={this.handleSelectTopTab}
                      indicatorColor="primary"
                      // textColor="primary"
                      variant="scrollable"

                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab label={<span className='tabHeading'>Map</span>} {...a11yPropsTop(0)} />
                    </Tabs>
                  </Paper>

                  <TabPanel value={this.state.selectedTopTab} index={0}>
                    <div className='mapwidth'>
                      {this.state.workorderdetails.locationLatLong && this.state.workorderdetails.locationLatLong.x !== 0 ?
                        <MyMapComponent
                          singleMarkers={{ lng: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.x : 0, lat: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.y : 0 }}
                          center={{ lng: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.x : 0, lat: this.state.workorderdetails.locationLatLong ? this.state.workorderdetails.locationLatLong.y : 0 }}
                          useStyle={this.state.useStyle}
                          iconColor={'33cc33'}
                          zoom={18}
                          height="35vh"
                          showMarker={true}

                        />

                        :
                        <h3>No location data</h3>
                      }
                    </div>
                  </TabPanel>
                </Grid>
              </Grid>
            </div>
          )
        }
        <br />
        <br />
        <br />
        <div className={'tabsDiv'}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12}>
              <Paper square>
                <Tabs
                  value={this.state.selected}
                  onChange={this.handleSelect}
                  indicatorColor="primary"
                  // textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label={this.loaderTab('History', WOhistoryItems, WOhistoryItems.length, this.state.historyLoading)} {...a11yProps(0)} />
                  <Tab label={this.loaderTab('Documents', DocPhotosItems, DocPhotosItems.length, this.state.documentloading)} {...a11yProps(1)} />
                  <Tab disabled={this.props.role !== global.worxOnline_Admin} label={this.loaderTab('Timesheets', worktimeItems, worktimeItems.length, this.state.timesheetLoading)} {...a11yProps(2)} />
                  <Tab disabled={this.props.role !== global.worxOnline_Admin} label={this.loaderTab('Plant and Fleet Usage', plantusageItems, plantusageItems.length, this.state.plantUsageLoading)} {...a11yProps(3)} />
                  <Tab label={this.loaderTab('Material Usage', materialWOItems, materialWOItems.length, this.state.loadingwoMaterials)}{...a11yProps(4)} />
                  <Tab label={this.loaderTab('Related Work Orders', relatedWOItems, relatedWOItems.length, this.state.loadingrelatedWO)}{...a11yProps(5)} />
                  {AssetWOItems && <Tab label={this.loaderTab('Assets', AssetWOItems, AssetWOItems.length, this.state.loadingAssetDetail)}{...a11yProps(6)} />}
                  {meterReadingItems && meterReadingItems.length > 0 && <Tab label={this.loaderTab('Meter Readings', meterReadingItems, meterReadingItems.length, this.state.meterReadingLoading)}{...a11yProps(7)} />}
                  <Tab label={<span className='tabHeading'>Comment</span>}  {...a11yProps(8)} />

                </Tabs>
              </Paper>
            </Grid>
          </Grid>

          <TabPanel value={this.state.selected} index={0}>
            {!this.state.historyLoading ?
              <>
                {(WOhistoryItems && WOhistoryItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={WOhistoryItems}
                      columns={WOhistorycolumns}
                      // fileName={'Timesheet'}
                      // columnChooser={'prestartchecklistColumns'}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.historyLoading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <>
                <br />
                <Spinner size='large' type={'converging-spinner'} className="loader" />
              </>
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={1}>
            {!this.state.loading ?
              <>
                {(DocPhotosItems && DocPhotosItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={DocPhotosItems}
                      columns={DocPhotoscolumns}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                      viewFile={(e: any) => this.viewdocument(e)}
                      handleOpen={(e: any) => this.handleOpen(e)}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={2}>
            {!this.state.loading ?
              <>
                {(worktimeItems && worktimeItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={true}
                      groupable={true}
                      rows={worktimeItems}
                      columns={worktimecolumns}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>
          <TabPanel value={this.state.selected} index={3}>
            {(plantusageItems && plantusageItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={plantusageItems}
                  columns={plantusagecolumns}
                  // fileName={'Timesheet'}
                  // columnChooser={'prestartchecklistColumns'}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loading}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={4}>
            {(materialWOItems && materialWOItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={materialWOItems}
                  columns={materialcolumns}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loadingmaterialWO}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          <TabPanel value={this.state.selected} index={5}>
            {(relatedWOItems && relatedWOItems.length > 0) && (
              <div className="tabStripDiv">
                <AssignDatatable
                  style={{ width: '100%' }}
                  filteralbe={false}
                  groupable={false}
                  rows={relatedWOItems}
                  columns={relatedWOcolumns}
                  selectionMode={false}
                  showHideColumnMenu={false}
                  callAfterInit={this.state.callAfterInit}
                  stopCommsAfterInit={() => this.stopCommsAfterInit()}
                  loading={this.state.loadingrelatedWO}
                  allowExportSelectedData={false}
                  showNewBtn={false}
                  hideExport={true}
                  hideSearchPanel={true}
                  showCommandBtn={false}
                  showViewDetailBtn={false}
                />
              </div>
            )}
          </TabPanel>
          {!this.state.loadingAssetDetail ?
            <>
              <TabPanel value={this.state.selected} index={6}>
                {(AssetWOItems && AssetWOItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={false}
                      groupable={false}
                      rows={AssetWOItems}
                      columns={AssetWOcolumns}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loadingAssetDetail}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </TabPanel>
            </>
            :
            <>
              <br />
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            </>
          }
          {meterReadingItems && meterReadingItems.length > 0 && <TabPanel value={this.state.selected} index={7}>
            {!this.state.loading ?
              <>
                {(meterReadingItems && meterReadingItems.length > 0) && (
                  <div className="tabStripDiv">
                    <AssignDatatable
                      style={{ width: '100%' }}
                      filteralbe={true}
                      groupable={true}
                      rows={meterReadingItems}
                      columns={meterReadingColumns}
                      selectionMode={false}
                      showHideColumnMenu={false}
                      callAfterInit={this.state.callAfterInit}
                      stopCommsAfterInit={() => this.stopCommsAfterInit()}
                      loading={this.state.loading}
                      allowExportSelectedData={false}
                      showNewBtn={false}
                      hideExport={true}
                      hideSearchPanel={true}
                      showCommandBtn={false}
                      showViewDetailBtn={false}
                    />
                  </div>
                )}
              </>
              :
              <Spinner size='large' type={'converging-spinner'} className="loader" />
            }
          </TabPanel>}
          <TabPanel value={this.state.selected} index={8}>
            <div className="m-20">
              {workorderdetails && workorderdetails.comments ?
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12}>
                        {/* <div>{workorderdetails.WOComments}</div> */}
                        <div dangerouslySetInnerHTML={{ __html: `${workorderdetails.comments.replace(/\n/g, "<br />")}` }}></div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                :
                <p className={'alignCenter'}>No comment found</p>}
            </div>
          </TabPanel>

        </div>
        <div>{this.state.workorderdetails && this.renderEditWOPanel()}</div>
        <div>{this.renderAttachmentModal()} </div>
        <div>{this.renderNotes()} </div>
        <div>{this.state.showMapPanel && this.rendermapModal()}</div>
        {(this.state.openPlantAssignModal) && (this.renderPlantAssignModal())}
      </div >

    );
  }
}


function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function a11yPropsTop(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
